import { FC, useEffect, useState } from "react";
import { BaseProp } from "entities/BaseProp";
import StickySideBar from "./StickySideBar";
import AccordianProductInfo from "./AccordianProductInfo";
import TransactionSlider from "./TransactionSlider";
import axiosInstance from "api/AxiosInstance";
import { useParams } from "react-router-dom";
import { BaseApiResponse } from "entities/ApiResponse";
import { ProductDetailsDTO } from "entities/ProductDetailsDTO";
import { Helmet } from "react-helmet-async";
import ProductReview from "./ProductReview";
import SuggestedProducts from "./SuggestedProducts";
import PageLoader from "components/CSCLedLoader/PageLoader";
import SkeletonProductDetailCsc from "./SkeletonProductDetailCsc";
import ProductDownload from "./ProductDownload";
import handleScrollToEl from "utils/HandleScrollToEl";
import ButtonCSCLed from "shared/Button/ButtonCSCLed";
import HelmetComponent from "components/HelmetComponent/HelmetComponent";

export interface ProductDetailsCSCProps extends BaseProp {}

const ProductDetailsCSC: FC<ProductDetailsCSCProps> = ({ className = "" }) => {
  const { prodid } = useParams<{ prodid: string }>();

  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [isLoader, setisLoader] = useState<boolean>(false);
  const [dataResult, setDataResult] =
    useState<BaseApiResponse<ProductDetailsDTO> | null>(null);

  const assignValues = (filterProp: string, filterVal: any) => {
    setDataResult((prevDataResult) => {
      if (prevDataResult && prevDataResult.data) {
        return {
          ...prevDataResult,
          data: {
            ...prevDataResult.data,
            [filterProp]: filterVal,
          },
        };
      }
      return prevDataResult;
    });
  };

  const fetchData = async () => {
    setIsPageLoading(true);
    const response = await axiosInstance
      .post("/products/getProductDetails", {
        slugName: prodid,
      })
      .then((response) => {
        setDataResult(response.data);
        setIsPageLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsPageLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [prodid]);

  const renderSection1 = () => {
    if (!dataResult?.data) {
      return <></>;
    }

    return (
      <div className="listingSection__wrap border-none pb-0">
        {/* Title Images slider */}
        <TransactionSlider
          prodImage={dataResult.data.highResImagesList}
          status={dataResult.data.productStatus}
        />
        {/* Mobile view */}
        <div className="block lg:hidden">
          <StickySideBar data={dataResult.data} />
        </div>
        {/* Link to scroll to product download */}
        {dataResult.data.productDownloadTypes &&
          dataResult.data.productDownloadTypes.fileTypeList.length > 0 && (
            <ButtonCSCLed
              onClick={() => handleScrollToEl("divProductDownload")}
            >
              Jump right to the Spec Sheet and Downloads
            </ButtonCSCLed>
          )}
        <AccordianProductInfo
          isClearance={dataResult.data.isClearance}
          productDescription={dataResult.data.productDescription}
          specificationList={dataResult.data.specificationList}
          panelClassName="p-4 pt-3.5 text-slate-600 text-base dark:text-slate-300 leading-7"
        />
      </div>
    );
  };

  const renderNoProductFound = () => {
    return (
      <div className="nc-NoProductFound flex justify-center">
        <div className="py-16 max-w-xl">
          <div className="flex items-center p-5 border border-slate-200 dark:border-slate-700 rounded-xl overflow-hidden">
            <i className="text-6xl object-contain object-center mr-5 fi fi-rr-search-alt"></i>
            <div className="flex flex-col">
              <span className="text-black font-semibold text-2xl py-3">
                This page is no longer available.
              </span>
              <span>
                If you are looking for something specific, please contact
                info@csc-led.com and we will get back to you as soon as we can.
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <>
        {dataResult && prodid && (
          <>
            {/* Title & meta data */}
            <HelmetComponent
              title={`${
                dataResult.data ? dataResult.data.productName : prodid
              } | High-Quality LED Lighting from CSC LED`}
              metaDescription={
                dataResult.data ? dataResult.data.metaDescription : ""
              }
              metaKeywords={dataResult.data.metaKeywords}
            />
            {/**No Product Found */}
            {!dataResult.data && renderNoProductFound()}

            {dataResult.data && (
              <main className="container my-9 sm:my-11 flex">
                {/* CONTENT */}
                <div className="w-full lg:w-2/4 space-y-10 lg:pr-14 lg:space-y-14">
                  {renderSection1()}
                </div>
                {/* SIDEBAR */}
                <div
                  className="nc-StickySideBar lg:w-2/4"
                  data-nc-id="StickySideBar"
                >
                  <div className="hidden lg:block sticky top-28">
                    <StickySideBar
                      data={dataResult.data}
                      isShortDataList={false}
                    />
                  </div>
                </div>
              </main>
            )}
          </>
        )}
      </>
    );
  };

  const renderMain = () => {
    return (
      <>
        {renderHeader()}
        {dataResult?.data && (
          <>
            {dataResult.data.productDownloadTypes && (
              <ProductDownload data={dataResult.data.productDownloadTypes} />
            )}
            <div className="container py-9 sm:py-16 space-y-14">
              {dataResult.data.accessoriesItems && (
                <SuggestedProducts
                  key={"Accessories"}
                  pageTitle="Accessories"
                  productList={dataResult.data.accessoriesItems}
                  isShortDataList={false}
                />
              )}
              {dataResult.data.relatedProductItems && (
                <SuggestedProducts
                  key={"Related-Products"}
                  pageTitle="Related Products"
                  productList={dataResult.data.relatedProductItems}
                  isShortDataList={false}
                />
              )}
              {dataResult.data.substituteClearanceList && (
                <SuggestedProducts
                  key={`SuggestedSubstitutions`}
                  pageTitle="Suggested Substitutions"
                  productList={dataResult.data.substituteClearanceList}
                  isShortDataList={false}
                />
              )}
              <ProductReview
                onLoaderShow={setisLoader}
                data={dataResult.data}
                assignParameters={assignValues}
                isShortDataList={false}
              />
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <div
      className={`nc-ProductDetailsCSC ${className}`}
      data-nc-id="ProductDetailsCSC"
    >
      {!isPageLoading ? renderMain() : <SkeletonProductDetailCsc />}
      <PageLoader isLoading={isLoader} />
    </div>
  );
};

export default ProductDetailsCSC;
