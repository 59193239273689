import { BaseProp } from "entities/BaseProp";
import React, { FC, useEffect, useState } from "react";
import Divider from "components/Divider";
import HelmetComponent from "components/HelmetComponent/HelmetComponent";
import DarkskyHeading from "./DarkskyHeading";
import DarkSkyBody from "./DarkSkyBody";

interface DarkskyProductsProps extends BaseProp {}



const DarkskyProducts: FC<DarkskyProductsProps> = ({ className = "" }) => {






  return (
    <div
      className={`nc-DarkskyProducts container py-8 pb-1 lg:pt-20 space-y-16 lg:space-y-28 ${className}`}
      data-nc-id="DarkskyProducts"
    >
      {/* Title & meta data */}
      <HelmetComponent
        title="DarkSky Approved Lights | Fight Light Pollution with CSC LED"
        metaDescription="Explore CSC LED's DarkSky Approved lighting solutions designed to combat light pollution. Discover energy-efficient, environmentally-friendly products that support natural outdoor light levels and sustainability."
        metaKeywords="DarkSky Approved lights, Light pollution solutions, Environmentally-friendly lighting, Outdoor lighting solutions, Sustainable lighting products, CSC LED DarkSky products, 3000K LED lights, Reduce light pollution, DarkSky compliant lighting"
      />
      <div className="space-y-10 lg:space-y-14">
        <DarkskyHeading />
        <Divider />
        <DarkSkyBody/>
      </div>
    </div>
  );
};

export default DarkskyProducts;
