import HeadingText1 from "components/HeadingText/HeadingText1";
import React from "react";

const APIQuestion = () => {
  return (
    <>
      {/* HEADING */}
      <div className="">
        <HeadingText1 className="">What Inventory Is Available?</HeadingText1>
        <div className="mt-2 dark:text-slate-400 text-sm sm:text-base">
          All CSC LED products which are displayed on our website are available
          through this API. This includes clearance, end of life, or even
          inactive products. Should you wish to not include these products
          on your website, filter them out via the Clearance and Lifecycle
          Status fields included in the API.
        </div>
      </div>
    </>
  );
};

export default APIQuestion;
