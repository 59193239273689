import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import SiteLanguage from "shared/Languages/SiteLanguage";
import { useBackgroundContext } from "hooks/useBackgroundContext";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const backgroundCSS=`bg-custom-grey halloween:bg-orange-500 border-t border-neutral-200`;

const hiddenTitle=".";

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "1",
    title: "Tools",
    menus: [
      { href: "/energy-savings", label: "Energy Savings" },
      { href: "/rebate-savings", label: "Rebate Savings" },
    ],
  },
  {
    id: "2",
    title: "Resources",
    menus: [
      { href: "/about-us", label: "About Us" },
      { href: "/policy-forms", label: "Policies & Forms" },
      { href: "/technical-inventory-api", label: "Inventory API" },
      { href: "/terms-conditions", label: "Terms and Conditions",additionalCss:"block sm:hidden" },
      { href: "/privacy-policy", label: "Privacy Policy",additionalCss:"block sm:hidden"  },
    ],
  },
  {
    id: "4",
    title: hiddenTitle,
    menus: [
      { href: "/terms-conditions", label: "Terms and Conditions",additionalCss:"hidden sm:block" },
      { href: "/privacy-policy", label: "Privacy Policy",additionalCss:"hidden sm:block"  },
    ],
  },
];

const Footer: React.FC = () => {
  const {isBackgroundImage} = useBackgroundContext();
  
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="pl-5 md:pl-0 text-sm">
        <h2 className={`font-semibold dark:text-neutral-200 ${isBackgroundImage ? `text-custom-grey`:`text-black`} ${menu.title==hiddenTitle && "opacity-0"}`}>
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <Link
                key={index}
                className={`dark:text-neutral-300 dark:hover:text-white ${isBackgroundImage ? `text-custom-grey`:`text-black`} ${item.additionalCss}`}
                to={item.href}
                target="_self"
                rel="noopener noreferrer"
                onClick={menu.title === "Tools" ? (() => {
                  window.location.href=`${item.href}`
                }):undefined}
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className={`nc-Footer relative py-10 lg:pt-28 lg:pb-24 dark:border-neutral-700 halloween:border-none ${!isBackgroundImage ? backgroundCSS:""}`}>
      <div className="container grid grid-cols-2 md:gap-y-10 gap-y-6 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
          <div className="grid grid-cols-4 md:gap-5 gap-1 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
            <div className="col-span-3 md:col-span-1">
              <Logo />
            </div>
            <div className="col-span-3 md:col-span-3 flex items-center pl-5 pb-3 md:pl-6 md:pb-0">
              <SiteLanguage />
            </div>
            <div className="col-span-3 md:col-span-3 flex items-center pl-5 pb-3 md:pl-6 md:pb-0">
              <SocialsList1 className="flex items-center space-x-2 lg:space-x-0 lg:flex-col lg:space-y-3 lg:items-start" />
            </div>          
          </div>
          {widgetMenus.map(renderWidgetMenuItem)}
        </div>
    </div>
  );
};

export default Footer;
