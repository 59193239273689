import HeadingText2 from "components/HeadingText/HeadingText2";
import React, { FC } from "react";
import { Helmet } from "react-helmet-async";

export interface PrivatePolicyProps {
  className?: string;
}

const content = [
  "For users logging on to the website, in order to be personally identifiable, the following information is collected and stored.",
  "When you register on the website, through other companies' products or services, or visit the company website, the site will collect your personal information, including but not limited to your company name, name, and email address.",
  "The company will automatically receive and record your browser, the server log information, including but not limited to your IP address, cookie information in this site and your web history requirements.",
  "<h2 class='text-2xl font-semibold'>The Use And Protection Of Information</h2><p class='mt-4'> The company will collect the contents of the information mentioned above.<br/>The company might use some of the pictures you upload on csc-led.com and share them on our various social media accounts.</p>",
];

const customerInfo = [
  `1. When the company has your consent to share the information.`,
  `2. When disclosing your personal information to provide you with requested products and services.`,
  `3. According to legal requirements, the company has the authority to make law or comply with legal directives, but the company will provide the appropriate jurisdiction.`,
  `4. In case of emergency, to safeguard the interests of users and the public.`,
  `5. In the case that other companies need to open, edit, or disclose personal information in the situation.`,
];

const revisedPolicy = `The revised privacy policy reserves the right to make changes.`;

const choice = [
  `If you no longer wish to receive the Company's promotional communications, you may "opt-out" of receiving them by following the instructions included in each communication or by e-mailing the Company at marketing@csc-led.com.`,
];

const register = [
  `When you register, you will be given the option to opt-in and subscribe to our regular update service. If you are subscribed to our update service, we will send you email alerts for new products, features, enhancements, special offers, contests, events of interest, and one-off marketing promotions.`,
  `At all times, we will offer you the opportunity to unsubscribe out of any service or update to which you have subscribed. Any e-mail we send you will contain an easy automated unsubscribe link so that you can opt-out of that subscription. Simply follow the indications in the e-mail.`,
];

const PrivatePolicy: FC<PrivatePolicyProps> = ({ className = "" }) => {
  return (
    <div className={`nc-PrivatePolicy ${className}`} data-nc-id="PrivatePolicy">
      <Helmet>
        <title>Privacy Policy | CSC LED</title>
      </Helmet>

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-28">
        <div className="space-y-10 lg:space-y-14">
          {/* HEADING */}
          <div className="">
            <HeadingText2 className="">
              Privacy and Security Policy
            </HeadingText2>
            {content.map((paragraph, index) => (
              <span
                key={index}
                className="block mt-4 dark:text-neutral-400 text-black text-sm sm:text-base"
                dangerouslySetInnerHTML={{ __html: paragraph }}
              />
            ))}
            <span className="block mt-4 text-black dark:text-neutral-400 text-sm sm:text-base">
              <HeadingText2 className="mb-4">
                Customer information held by the company is confidential, with
                the following exceptions:
              </HeadingText2>
              {customerInfo.map((data, index) => (
                <React.Fragment key={index}>
                  {index !== 0 && <br />} {data}
                </React.Fragment>
              ))}
            </span>
            <span className="block mt-4 text-black dark:text-neutral-400 text-sm sm:text-base">
              {revisedPolicy}
            </span>
            <span className="block mt-4 text-black dark:text-neutral-400 text-sm sm:text-base">
              <HeadingText2 className="mb-4">
                Choice and Opt-out
              </HeadingText2>
              {choice.map((data, index) => (
                <React.Fragment key={index}>
                  {index !== 0 && <br />} {data}
                </React.Fragment>
              ))}
            </span>
            {register.map((paragraph, index) => (
              <span
                key={index}
                className="block mt-4 text-black dark:text-neutral-400 text-sm sm:text-base"
                dangerouslySetInnerHTML={{ __html: paragraph }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivatePolicy;
