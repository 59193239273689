import { BaseProp } from "entities/BaseProp";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import Contact_Us_Banner from "images/contact-us/Contact_Us_Banner.jpg";
import { Link } from "react-router-dom";
import { TypewriterEffect, TypewriterEffectSmooth } from "components/aceternityUI/typewriter-effect";

interface ContactHeaderProp extends BaseProp {
  bodyMsg: string;
}



const ContactHeader: FC<ContactHeaderProp> = ({ bodyMsg }) => {
  const wordsArray = bodyMsg.split(" ");

  const words = wordsArray.map(word => ({
    text: word,
  }));

  return (
    <div className="nc-ContactHeader relative">
      <div className={`nc-NcImage relative`} data-nc-id="NcImage">
        <img
          src={Contact_Us_Banner}
          className="w-full h-full object-cover"
          alt="Contact Us Banner"
        />
        <div className="px-5 sm:container sm:space-y-5 absolute inset-0 flex flex-col items-start justify-center text-white">
          <h1 className="text-lg font-bold tracking-tight sm:text-6xl">
            Contact Us
          </h1>
          <div className="w-60 sm:w-full text-xs sm:text-lg">
            <TypewriterEffectSmooth words={words} className="nc-TypewriterEffect " cursorClassName="inline-block rounded-sm w-[4px] h-6 bg-white"/>
            <p>
              Email:{" "}
              <Link to={`mailto:info@csc-led.com?Subject=Contact Us`}>
                info@csc-led.com
              </Link>
            </p>
            <p>
              Tel. <Link to={`tel:1-888-900-9885`}>1-888-900-9885</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactHeader;
