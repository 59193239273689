import React, { FC, useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet-async";
import BackOrdersPage from "./BackOrdersPage";
import OpenOrdersPage from "./OpenOrdersPage";
import ClosedOrdersPage from "./ClosedOrdersPage";
import NavItem from "shared/NavItem/NavItem";
import Nav from "shared/Nav/Nav";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import Divider from "components/Divider";
import HeadingText1 from "components/HeadingText/HeadingText1";

export interface YourOrdersProps {
  className?: string;
}


const YourOrdersPage: FC<YourOrdersProps> = ({ className = "" }) => {

  const [activeTab, setActiveTab] = useState(0);

  const tabs = ['Open Orders', 'Back Orders', 'Closed Orders'];
  const content = [
    'Content for Tab 1',
    'Content for Tab 2',
    'Content for Tab 3',
  ];
  const [searchValue1, setSearchQuery] = useState('');
  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
};
  return (
    <div
      className={`nc-ShippingAddressPage ${className}`}
      data-nc-id="ShippingAddressPage"
    >
      <div>
        <div>
          <Helmet>
            <title>Your Orders | CSCLED</title>
          </Helmet>
          <main className="container py-16 lg:pb-28 lg:pt-20 pt-8">
          <div className="mb-10">
            <HeadingText1 className="">Your Orders</HeadingText1>
          </div>     
          <div className="w-full mt-8">
              {/* <div className="flex">
              <Nav
          className="sm:space-x-2"
          containerClassName="relative flex w-full overflow-x-auto text-sm md:text-base hiddenScrollbar"
        >
                {tabs.map((tab, index) => (                 
                  <NavItem
                  key={index}
                  isActive={activeTab === index}
                  onClick={() => setActiveTab(index)}
                >
                  {tab}
                </NavItem>
                ))}
                 </Nav>
                 <div className="w-full max-w-full md:max-w-sm lg:max-w-custom sm:flex-auto">
                  <label htmlFor="search" className="sr-only"></label>
                  <div className="relative">
                      <input
                        id="search"
                        name="search"
                        className="block w-full py-3.5 rounded-md border-0 shadow pl-10 pr-3 text-gray-900 bg-gray-50 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
                        placeholder="Search by CSC LED order No. or PO no."
                        type="search"
                        value={searchValue1}
                        onChange={handleSearchChange}
                      />
                      <div className="cursor-pointer absolute inset-y-0 left-0 flex items-center pl-3">
                        <MagnifyingGlassIcon
                          className="h-5 w-5 text-blue-900"
                          aria-hidden="true"
                        />
                      </div>
                    </div>                  
                </div>
              </div>      */}
              
                {/* New design */}
                <div className=" flex flex-col my-8 items-start md:items-center justify-between md:flex-row md:space-x-2 md:space-y-0">
              <div className="flex sm:w-auto w-full justify-center flex-wrap sm:justify-start">
              <Nav
          className="sm:space-x-2"
          containerClassName="relative flex w-full overflow-x-auto text-sm md:text-base hiddenScrollbar"
        >
                {tabs.map((tab, index) => (                 
                  <NavItem
                  key={index}
                  isActive={activeTab === index}
                  onClick={() => setActiveTab(index)}
                >
                  {tab}
                </NavItem>
                ))}
                 </Nav>
              </div>

              <div className="flex md:justify-end justify-start w-full md:w-100 sm:w-full">
                <div className="mt-4 md:ml-2 ml-0 w-full max-w-full md:max-w-lg lg:max-w-s md:mt-0 sm:flex-auto">
                  <label htmlFor="search" className="sr-only"></label>
                  <div className="relative">
                  <input
                        id="search"
                        name="search"
                        className="block w-full py-3.5 rounded-md border-0 shadow pl-10 pr-3 text-gray-900 bg-gray-50 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
                        placeholder="Search by CSC LED order No. or PO no."
                        type="search"
                        value={searchValue1}
                        onChange={handleSearchChange}
                      />
                      <div className="cursor-pointer absolute inset-y-0 left-0 flex items-center pl-3">
                        <MagnifyingGlassIcon
                          className="h-5 w-5 text-blue-900"
                          aria-hidden="true"
                        />
                      </div>
                    </div> 
                </div>
              </div>
            </div>
            <Divider/>

              <div className="mt-8">
              {activeTab === 0 ? (
                <OpenOrdersPage searchValue={searchValue1}/>
              ) :('')}

                  {activeTab === 1 ? (
                      <BackOrdersPage searchValue={searchValue1}/>
                    ) : ('')}
                     {activeTab === 2 ? (
                      <ClosedOrdersPage searchValue={searchValue1}/>
                    ) : ('')}
                </div>
              </div>
            </main>
        </div>
      </div>
    </div>
  );
};

export default YourOrdersPage;
