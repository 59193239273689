import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/solid";
import { BaseProp } from "entities/BaseProp";
import React, { FC, Fragment } from "react";
import ButtonCSCLed from "shared/Button/ButtonCSCLed";
import ButtonSecondary from "shared/Button/ButtonSecondary";

interface Props extends BaseProp {
  isModalOpen: boolean;
  onCloseModal: () => void;
  modalBody?: string;
}

const ModalAccountPassword: FC<Props> = ({
  className = "",
  isModalOpen,
  onCloseModal,
  modalBody = "",
}) => {
  const notifyAccountSave = () => {
    return (
      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={onCloseModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 sm:p-10 text-left align-middle shadow-xl transition-all">
                  <div>
                    <div className="mx-auto flex h-20 w-20 items-center justify-center rounded-full border-4 border-green-200">
                      <CheckIcon
                        aria-hidden="true"
                        className="h-14 w-14 text-green-600"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-[1.2rem] font-medium leading-8 text-gray-900"
                      >
                       Password updated!
                      </Dialog.Title>
                      {modalBody && (
                        <div
                          className="mt-5 ck-content"
                          dangerouslySetInnerHTML={{ __html: modalBody }}
                        ></div>
                      )}
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 justify-center">
                    <ButtonCSCLed
                      type="button"
                      className=""
                      href={"/account-change-password"}
                      focusCssClass={false}
                      onClick={onCloseModal}
                    >
                     Thanks computer!
                    </ButtonCSCLed>                   
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };
  return <>{notifyAccountSave()}</>;
};

export default ModalAccountPassword;
