import {
  GenerateTokenLangArr,
  InvProgrammingLanguage,
} from "entities/TechnicalInventoryApi";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import CodeContainer from "./CodeContainer";
import { config } from "utils/Constance";
import Divider from "components/Divider";
import HeadingText1 from "components/HeadingText/HeadingText1";

const GenerateAccessToken = () => {
  const [activeTab, setActiveTab] = useState(0);

  const renderProgrammingTab = (
    item: InvProgrammingLanguage,
    index: number
  ) => {
    const isActive = index === activeTab;
    return (
      <li className="me-2" key={index}>
        <Link
          to={""}
          onClick={() => setActiveTab(index)}
          className={`inline-block py-4 px-1 border-b-2 rounded-t-lg hover:text-gray-600 dark:hover:text-gray-300 ${
            isActive
              ? "border-black text-gray-600"
              : "border-transparent text-slate-500 dark:text-slate-400"
          }`}
        >
          {item.language}
        </Link>
      </li>
    );
  };

  return (
    <>
      {/* HEADING */}
      <div className="max-w-screen-sm">
        <HeadingText1 className="">Generating Your Access Token</HeadingText1>
      </div>
      <Divider />
      <div className="space-y-4 dark:text-slate-400 text-sm sm:text-base">
        <div className="">
          Before utilizing the API below you will need to generate your access
          token using your email address and password registered with CSC LED.
        </div>
        <div>
          If you are not yet registered with CSC LED for the purposes of
          accessing our API, please contact <b>info@csc-led.com</b> and request
          Inventory API access.
        </div>
        <div>
          Once you have your login and password, your next step is to generate
          your access token by sending a POST request to '
          {`${config.url.Tech_API_URL}/token`}' with your registered email ID
          and password for API access with grant type = password.
        </div>
        <div>Here are some examples for generating your access token:</div>
        <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
          <ul className="flex flex-wrap -mb-px space-x-5">
            {GenerateTokenLangArr.map(renderProgrammingTab)}
          </ul>
        </div>
        <div className="overflow-auto p-2.5 border border-gray-200 dark:text-gray-400 dark:border-gray-700 select-all">
          {
            <CodeContainer
              codeText={GenerateTokenLangArr[activeTab].tabContent}
            />
          }
        </div>
        <div>
          If your credentials are correct you will receive a JSON response with
          a 200 (OK) response code (as shown below).
        </div>
        <div>
          <div className="overflow-auto border border-gray-200 dark:text-gray-400 dark:border-gray-700">
            <CodeContainer
              codeText={`{
    "access_token": "123456789-0123456-cB9GVU9PNCGosw0edOKNPF-9wmGdPwaOvaoR2ehdazR_2Xr_ZxySf2e2oDxiMGeMtGUGRYXjaCWVjinmKrdOxQj6k2Gcr4Bts-g55GOG3s1iLNVSf0wb2_ZApi4WuIBQddUIuJ9v8kYnUoHqap95XO1zCe4xzxQNebQrwss5AHpwRANI1deVaZWPS3dX6s0-5gHdKoO1utFRZAvl36b7HBUjsejWcq1HLZv2dwsjx8xX8M885B7qrfjRO305",  // updated access_token
    "token_type": "bearer",
    "expires_in": 3599,
    "refresh_token": "12345678-99ae-4aa6-a278-7aa1c36721c9",  // new refresh_token
    "userName": "youremail@gmail.com",
    "issued": "Mon, 22 Jan 2024 11:26:59 GMT",
    "expires": "Mon, 22 Jan 2024 12:26:59 GMT"
}`}
            />
          </div>
        </div>
        <div>
          If your information is not correct or up to date, you will receive an
          error with status 400 (Bad request) as shown below.
        </div>
        <div>
          <div className="overflow-auto">
            <CodeContainer
              codeText={`{
    "error": "invalid_grant",
    "error_description": "Either email or password is incorrect"
}`}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GenerateAccessToken;
