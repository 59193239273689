import React, { FC, lazy } from "react";
import InvParameters from "./InvParameters";
import APIQuestion from "./APIQuestion";
import APIEndpointSample from "./APIEndpointSample";
import InvTokenRefresh from "./InvTokenRefresh";
import GenerateAccessToken from "./GenerateAccessToken";
import HelmetComponent from "components/HelmetComponent/HelmetComponent";
import BackgroundBoxHeader from "./BackgroundBoxHeader";


interface TechInvAPIProps {
  className?: string;
}

const TechInvAPI: FC<TechInvAPIProps> = ({ className="" }) => {
  return (
    <div
      className={`nc-TechnicalInventoryAPI ${className}`}
      data-nc-id="TechnicalInventoryAPI"
    >
      {/* Title & meta data */}
      <HelmetComponent
        title="CSC LED Technical Inventory API | Real-Time Product Data Access"
        metaDescription="Explore CSC LED's Technical Inventory API for seamless integration and real-time access to product availability and technical specifications. Streamline your processes with our innovative API solution."
        metaKeywords="CSC LED Technical Inventory API, LED product API, Real-time product data, LED inventory API, Product availability API, Technical specifications API, API for LED lighting solutions, CSC LED data integration"
      />
      <BackgroundBoxHeader/>
      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-28">
        <div className="space-y-10 lg:space-y-14">
          <GenerateAccessToken />
          <InvTokenRefresh />
          <APIEndpointSample />
          <APIQuestion />
          <InvParameters />
        </div>
      </div>
    </div>
  );
};

export default TechInvAPI;
