import { BaseProp } from "entities/BaseProp";
import React, { FC } from "react";
import { TeamMember } from "./OurTeam";
import NcImage from "shared/NcImage/NcImage";
import { config } from "utils/Constance";

interface TeamCardProps extends BaseProp {
  item: TeamMember;
  openModal: (objId: number) => void;
}

const TeamCard: FC<TeamCardProps> = ({ className = "", item,openModal }) => {
  return (
    <div
      key={item.id}
      className="nc-TeamCard relative group max-w-sm [perspective:1000px]"
    >
      <div
        onClick={() => openModal(item.id)}
        className="cursor-pointer relative aspect-h-1 aspect-w-1 rounded-xl shadow-xl 
      transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]"
      >
        <div className="absolute inset-0 h-full w-full rounded-xl [backface-visibility:hidden]">
          {item.employeePictureUrl && (
            <NcImage
              containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden"
              className="absolute inset-0 object-cover"
              src={`${config.url.AWS_S3_URL}${item.employeePictureUrl}`}
            />
          )}
        </div>
        <div className="absolute inset-0 h-full w-full rounded-xl [transform:rotateY(180deg)] [backface-visibility:hidden]">
          {item.employeePictureUrl && (
            <NcImage
              containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden"
              className="absolute inset-0 object-cover"
              src={`${config.url.AWS_S3_URL}${
                item.employeePictureUrl2
                  ? item.employeePictureUrl2
                  : item.employeePictureUrl
              }`}
            />
          )}
        </div>
      </div>
      <div className="px-2 py-2 mt-2">
        <h3 className="text-lg font-semibold text-csccolor md:text-xl dark:text-neutral-200">
          {item.employeeName}
        </h3>
        <span className="block text-sm text-black-500 sm:text-base dark:text-black-400 halloween:text-black">
          {item.department}
        </span>
        <span className="block text-sm text-black-500 sm:text-base dark:text-black-400 halloween:text-black">
          {item.jobTitle}
        </span>
      </div>
    </div>
  );
};

export default TeamCard;
