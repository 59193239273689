import axiosInstance from "api/AxiosInstance";
import CSCProductCard from "components/CSCProductCard";
import FilterClearance from "containers/Clearance/FilterClearance";
import SellSheetPagination from "containers/SellSheet/SellSheetPagination";
import { BaseApiResponse } from "entities/ApiResponse";
import { BaseProp } from "entities/BaseProp";
import { ClearanceDTO, ClearanceFilterParameter } from "entities/ClearanceDTO";
import usePagination from "hooks/usePagination";
import React, { FC, useEffect, useState } from "react";
import { stringToSlug } from "utils/stringToSlug";

interface DarkSkyBody extends BaseProp {}

const DarkSkyBody: FC<DarkSkyBody> = ({ className = "" }) => {
  const [dataResult, setDataResult] =
    useState<BaseApiResponse<ClearanceDTO> | null>(null);

  const [filters, setFilters] = useState<ClearanceFilterParameter>({
    productTypeId: 0,
    productSeriesId: 0,
  });

  const fetchData = async () => {
    const response = await axiosInstance
      .get("/products/getDarkSkyInventory")
      .then((response) => {
        setDataResult(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filteredItems = dataResult?.data.productDataList?.filter((item) => {
    if (
      filters.productSeriesId &&
      item.productSeriesId !== filters.productSeriesId
    ) {
      return false;
    }
    if (filters.productTypeId && item.productTypeId !== filters.productTypeId) {
      return false;
    }
    const searchTextLower = filters.searchText?.toLowerCase();
    if (
      searchTextLower &&
      !(
        item.productName.toLowerCase().includes(searchTextLower) ||
        item.productNumber.toLowerCase().includes(searchTextLower)
      )
    ) {
      return false;
    }
    return true;
  });

  const {
    currentItems,
    currentPage,
    itemsPerPage,
    onPageChange,
    setCurrentPage,
  } = usePagination(filteredItems, 24, "scrollPaginationTab");

  const handleFilterChange = (
    filterName: string,
    value: number | string | undefined
  ) => {
    setFilters((prevFilters) => ({ ...prevFilters, [filterName]: value }));
    setCurrentPage(1);
  };

  const renderProductCards = () => {
    return (
      <>
        {dataResult?.data && (
          <main>
            {/* FILTER */}
            <FilterClearance
              data={dataResult.data}
              filterParam={filters}
              handleFilterChange={handleFilterChange}
              filteredItems={filteredItems}
              labelTitle="DarkSky Product Filters"
            />
            {/**Render product tiles */}
            {currentItems && currentItems.length > 0 ? (
              <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
                {currentItems.map((item, index) => (
                  <CSCProductCard
                    name={item.productName}
                    category={item.productNumber}
                    description={item.prodDescription}
                    image={item.lowResImages || undefined}
                    link={`/product-details/${stringToSlug(
                      item.productNumber
                    ).toUpperCase()}`}
                    key={index}
                    linkTarget="_self"
                  />
                ))}
              </div>
            ) : (
              <MatchNotFound />
            )}

            {/* PAGINATION */}
            <SellSheetPagination
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={filteredItems?.length as number}
              onPageChange={onPageChange}
            />
          </main>
        )}
      </>
    );
  };

  const MatchNotFound = () => {
    return (
      <div className={`nc-MatchNotFound ${className}`}>
        <div className="pt-16 max-w-3xl">
          <div className="items-center py-3 px-4 text-base border border-slate-200 dark:border-slate-700 rounded-xl overflow-hidden">
            <p>We couldn’t find what you’re looking for.</p>
            <p>Try refining your search or editing your filter settings.</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-DarkSkyBody text-white scroll-mt-24 ${className}`} id="scrollPaginationTab">{renderProductCards()}</div>
  );
};

export default DarkSkyBody;
