import { FC, useEffect } from "react";
import FeaturedProducts from "./FeaturedProducts";
import BrochureContainer from "./BrochureContainer";
import OurPurposeContainer from "./OurPurposeContainer";
import NcImage from "shared/NcImage/NcImage";
import { config } from "utils/Constance";
import TopSellingProducts from "./TopSellingProducts";
import { HomePageBannerList } from "entities/HomePageBannerList";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getHomePageData } from "store/slices/homePageSlice";
import BannerCarousel from "./BannerCarousel";
import SkeletonHomePage from "./SkeletonHomePage";
import { BaseBanners } from "entities/BaseEntities";
import IframeInstagram from "./IframeInstagram";
import HelmetComponent from "components/HelmetComponent/HelmetComponent";

interface HomePageProps {
  className?: string;
}

const HomePage: FC<HomePageProps> = ({ className = "" }) => {
  const dispatch = useAppDispatch();
  const homePageData = useAppSelector(
    (state) => state.homePage.data
  ) as HomePageBannerList;

  useEffect(() => {
    if (!homePageData) {
      dispatch(getHomePageData());
    }
  }, [dispatch, homePageData]);

  const isAPILoad = useAppSelector(
    (state) => state.homePage.isLoading
  ) as boolean;

  if (isAPILoad) {
    return (
      <>
        <SkeletonHomePage />
      </>
    );
  }

  const renderDivider = () => {
    return (
      <hr className="border-slate-200 dark:border-slate-700 halloween:border-white" />
    );
  };

  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* Title & meta data */}
      <HelmetComponent 
        title="CSC LED | Innovative LED Lighting Solutions for All Industries"
        metaDescription="Discover CSC LED's wide range of sustainable and high-performance LED lighting products. From commercial to industrial solutions, we provide energy-efficient lighting designed for your needs. Learn more about our products and expertise."
        metaKeywords="CSC LED lighting solutions, Energy-efficient LED lights, Sustainable lighting products, Commercial LED lighting, Industrial LED solutions, LED lighting expertise, High-performance LED products, Innovative lighting technology, LED lighting for all industries"
        />
      {/* Banner Image */}
      {homePageData?.homePageBannerList && (
        <>
          <BannerCarousel
            images={homePageData.homePageBannerList as BaseBanners[]}
          />
        </>
      )}

      <div className="container pt-8 pb-16 lg:pb-28 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-28">
        {/*==Top Selling Products=== */}
        {homePageData?.topSellingProducts &&
          homePageData.topSellingProducts.aaData.length > 0 && (
            <>
              <TopSellingProducts
                productList={homePageData.topSellingProducts.aaData}
                productTitle={homePageData.topSaleProductTitle}
              />
              {renderDivider()}
            </>
          )}

        {homePageData?.featuredProductList && (
          <>
            <FeaturedProducts
              productList={homePageData.featuredProductList}
              headerTitle={homePageData.featureProdHeaders}
            />
            {renderDivider()}
          </>
        )}

        {homePageData?.brochuresList && (
          <>
            <BrochureContainer
              brochureList={homePageData.brochuresList}
              headerTitle={homePageData.brochuresHeaders}
            />
            {renderDivider()}
          </>
        )}

        <OurPurposeContainer />
        {renderDivider()}

        <IframeInstagram />

        <a
          target="_blank"
          href={`${homePageData?.miniBannerImageLink}`}
          className={`nc-SectionAds block w-full rounded-xl overflow-hidden ${className}`}
        >
          <NcImage
            className="w-full"
            src={`${config.url.ERP_Domain}${homePageData?.miniBannerImageURL}`}
          />
        </a>
      </div>
    </div>
  );
};

export default HomePage;
