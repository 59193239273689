import { Link } from "react-router-dom";
import { LogoProps } from "./Logo";
import csclogo from "images/CSCLEDLogo2024.png";
import cscseasonlogo from "images/CSC_LED_Logo_Halloween_v3.png";


const DefaultLogo: React.FC<LogoProps> = ({
  img = csclogo,
  imgLight = csclogo,
  className = "flex-shrink-0",
}) => {
  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-slate-600 ${className}`}
    >
      {/* This component is specific for mobile view to avoid Darksky background */}
      {img ? (
        <img
          className={`block max-h-16 sm:max-h-20 ${
            imgLight ? "dark:hidden halloween:hidden" : ""
          }`}
          src={img}
          alt="Logo"
        />
      ) : (
        "Logo Here"
      )}
      {imgLight && (
        <img
          className="hidden max-h-8 sm:max-h-10 dark:block"
          src={imgLight}
          alt="Logo-Light"
        />
      )}
      {cscseasonlogo && (
        <img
          className="hidden max-h-8 sm:max-h-10 halloween:block"
          src={cscseasonlogo}
          alt="Seasonal-Logo"
        />
      )}
    </Link>
  );
};

export default DefaultLogo