import React from "react";
import { Link } from "react-router-dom";
import csclogo from "images/CSCLEDLogo2024.png"
import cscseasonlogo from "images/CSC_LED_Logo_Halloween_v3.png"
import csclogowhite from "images/darksky/CSC_LED_Logo_2024_White.png"
import { useBackgroundContext } from "hooks/useBackgroundContext";


export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = csclogo,
  imgLight = csclogo,
  className = "flex-shrink-0",
}) => {

  const {isBackgroundImage} = useBackgroundContext();

  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-slate-600 ${className}`}
    >
      {/* THIS USE FOR MY CLIENT */}
      {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
      {img ? (
        <img
          className={`block max-h-16 sm:max-h-20 ${
            imgLight ? "dark:hidden halloween:hidden" : ""
          }`}
          src={isBackgroundImage ? csclogowhite : img}
          alt="Logo"
        />
      ) : (
        "Logo Here"
      )}
      {imgLight && (
        <img
          className="hidden max-h-8 sm:max-h-10 dark:block"
          src={imgLight}
          alt="Logo-Light"
        />
      )}
       {cscseasonlogo && (
        <img
          className="hidden max-h-8 sm:max-h-10 halloween:block"
          src={cscseasonlogo}
          alt="Seasonal-Logo"
        />
      )}
    </Link>
  );
};

export default Logo;
