import Glide from "@glidejs/glide";
import Heading from "components/Heading/Heading";
import { BrochureDTO } from "entities/HomePageBannerList";
import React, { FC, useEffect, useId } from "react";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import { config } from "utils/Constance";
import HomePagination from "./HomePagination";
import usePagination from "hooks/usePagination";

interface BrochureProps {
  className?: string;
  brochureList: BrochureDTO[];
  headerTitle?: string;
}

const BrochureContainer: FC<BrochureProps> = ({
  className = "",
  brochureList,
  headerTitle,
}) => {
  {
    /**Hook for pagination */
  }
  const { currentItems, currentPage, itemsPerPage, totallength, onPageChange } =
    usePagination(brochureList, 3, "Brouchure");

  const renderTiles = () => {
    return (
      <div className="mt-10 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
        {currentItems.map((item, index) => (
          <a
            key={index}
            href={item.pdfurl ? `${item.pdfurl}` : ""}
            className="group block"
            target="_blank"
          >
            <NcImage
              containerClassName="w-2/4 sm:w-full sm:aspect-w-8 sm:aspect-h-5 overflow-hidden rounded-lg group-hover:opacity-75"
              className="h-full w-full object-cover object-center"
              src={`${item.imageFileUrl}`}
            />
            <h3 className="mt-4 font-medium text-lg sm:text-xl text-csccolor">
              {item.tittle}
            </h3>
            <p className="mt-2 text-base text-black">{item.description}</p>
          </a>
        ))}
      </div>
    );
  };

  return (
    <div className={`nc-Brouchure scroll-mt-32 ${className}`} id="Brouchure">
      <div className={`flow-root`}>
        <Heading
          className="mb-12 text-neutral-900 dark:text-neutral-50"
          isCenter={false}
        >
          {headerTitle}
        </Heading>
        {renderTiles()}
        {/* PAGINATION */}
        {brochureList && brochureList.length > itemsPerPage && (
          <HomePagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={totallength as number}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </div>
  );
};

export default BrochureContainer;
