import { Boxes } from 'components/aceternityUI/background-boxes'
import React, { lazy } from 'react'
import { cn } from 'utils/aceternityutility'


const BackgroundBoxHeader = () => {
  return (
    <div className="h-96 relative w-full overflow-hidden bg-slate-900 flex flex-col items-center justify-center">
    <div className="absolute inset-0 w-full h-full bg-slate-900 z-20 [mask-image:radial-gradient(transparent,white)] pointer-events-none" />

    <Boxes />
    <h1 className={cn("md:text-4xl text-xl text-white relative z-20")}>
      CSC LED's Inventory API
    </h1>
    <p className="text-center mt-2 text-neutral-300 relative z-20">
      Connect today and unlock real-time product data for your website.
    </p>
  </div>
  )
}

export default BackgroundBoxHeader
