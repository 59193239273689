import React, { FC } from "react";
import darkskylogo from "../../images/darksky/DarkSky-Approval-Logo.png";
import { BaseProp } from "entities/BaseProp";


const darkskyHeadingArr = [
  `Light pollution is the human-made alteration of outdoor light levels from those occurring naturally.`,
  `When we over-light, fail to use timers and sensors, or use the wrong colour of light, we can negatively affect many parts of our world.`,
  `We Offer DarkSky Approved Products (when set to 3000K) to Help Fight Against Light Pollution`,
];

interface DarkskyHeadingProps extends BaseProp{

}

const DarkskyHeading:FC<DarkskyHeadingProps> = ({className=""}) => {
  const renderLargeBoldFonts = (value: string) => (
    <span className="font-medium">{value}</span>
  );
  return (
    <div
      className={`mt-3 space-y-8 flex flex-col sm:flex-row sm:items-center group ${className}`}
    >
      <div className="flex flex-col flex-grow">
        <div className="flex flex-col text-custom-grey dark:text-slate-300 sm:max-w-4xl">
          <span className="text-6xl font-thin">
            LET'S BEAT {renderLargeBoldFonts("LIGHT")}
          </span>
          <span className="text-6xl my-3">
            {renderLargeBoldFonts("POLLUTION")} TOGETHER
          </span>
          {darkskyHeadingArr.map((items, index) => (
            <span
              key={index}
              className="my-1 font-semibold text-lg tracking-wide"
            >
              {items}
            </span>
          ))}
        </div>
      </div>
      <div
        className={`flex flex-shrink-0 sm:w-1/4 sm:ml-6 rounded-xl overflow-hidden mb-5 sm:mb-0`}
      >
        <div className="h-52 w-full">
          <img
            className="h-full w-full object-contain"
            alt="Certificate by DarkSky"
            src={darkskylogo}
          />
        </div>
      </div>
    </div>
  );
};

export default DarkskyHeading;
