import { Listbox } from "@headlessui/react";
import Label from "components/Label/Label";
import { BaseProp } from "entities/BaseProp";
import React, { FC, useRef, useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import HeadingText1 from "components/HeadingText/HeadingText1";
import CartAllProductListCSC from "./CartAllProductListCSC";

interface SearchProductListboxProp extends BaseProp {
  inventoriesList: InventoryItem[];
}

export interface InventoryItem {
  inventoryId: number;
  productNumber: string;
}

const SearchProductListbox: FC<SearchProductListboxProp> = ({
  className = "",
  inventoriesList,
}) => {
  const [selectedInventory, setSelectedInventory] =
    useState<InventoryItem | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInventoryChange = (event: any) => {
    const selectedValue = event;
    setSelectedInventory(selectedValue);
  };

  const toggleListbox = () => {
    if (!inputRef.current) {
      setSearchQuery("");
      setTimeout(() => inputRef.current?.focus(), 0);
    }
  };

  const filteredInventoriesList = inventoriesList.filter((inv) =>
    inv.productNumber.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const renderListbox = () => {
    return (
      <div className={`nc-ProductListbox ${className}`}>
        <Listbox value={selectedInventory} onChange={handleInventoryChange}>
          <Label className="block text-xl font-medium leading-6 text-gray-900 mt-7">
            Product Number
          </Label>
          <div className="relative mt-4">
            <Listbox.Button
              onClick={toggleListbox}
              className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-csccolor sm:text-sm sm:leading-6"
            >
              <span className="block truncate">
                {selectedInventory?.productNumber || "--Select Inventory--"}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  aria-hidden="true"
                  className="h-5 w-5 text-gray-400"
                />
              </span>
            </Listbox.Button>
            <Listbox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white  text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm">
              {/* Search Box */}
              <div className="sticky top-0 z-10 bg-white px-3 py-2">
                <input
                  type="text"
                  value={searchQuery}
                  ref={inputRef}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search inventory..."
                  className="w-full rounded-md border border-gray-300 py-1 px-2 text-gray-900 shadow-sm focus:ring-1 focus:ring-csccolor sm:text-sm"
                />
              </div>
              <Listbox.Option
                key="default"
                value={undefined}
                className={({ active, selected }) =>
                  `group relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900 ${
                    active ? "bg-csccolor text-white" : "text-gray-900"
                  } ${selected ? "font-semibold bg-indigo-100" : "font-normal"}`
                }
              >
                <span className="block truncate">--Select Inventory--</span>
              </Listbox.Option>
              {filteredInventoriesList.map((inv: any) => (
                <Listbox.Option
                  key={inv.inventoryId}
                  value={inv}
                  className={({ active, selected }) =>
                    `group relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900 ${
                      active ? "bg-csccolor text-white" : "text-gray-900"
                    } ${
                      selected ? "font-semibold bg-indigo-100" : "font-normal"
                    }`
                  }
                >
                  <span className="block truncate">{inv.productNumber}</span>

                  <span
                    className={`absolute inset-y-0 left-0 flex items-center pl-1.5 ${
                      inv.inventoryId === selectedInventory?.inventoryId
                        ? "text-indigo-600"
                        : "hidden"
                    }`}
                  >
                    <CheckIcon aria-hidden="true" className="h-5 w-5" />
                  </span>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        </Listbox>
      </div>
    );
  };

  return (
    <div className="nc-SearchProductListbox">
      <HeadingText1 className="">
        Know what you need? Search here!
      </HeadingText1>
      {renderListbox()}
      {/* Display more details based on the selected inventory ID */}
      {selectedInventory && (
        <CartAllProductListCSC slugName={selectedInventory.productNumber} />
      )}
    </div>
  );
};

export default SearchProductListbox;
