import React, { FC, useState, useRef } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";
import Select from "shared/Select/Select";
// import ReCAPTCHA from "react-google-recaptcha";
import ReactSwitch from "react-switch";
import axiosInstance from "api/AxiosInstance";
import GoogleRecaptcha from "components/GoogleRecaptcha";
import HelmetComponent from "components/HelmetComponent/HelmetComponent";

export interface PageSignUpProps {
  className?: string;
}

// const loginSocials = [
//   {
//     name: "Continue with Facebook",
//     href: "#",
//     icon: facebookSvg,
//   },
//   {
//     name: "Continue with Twitter",
//     href: "#",
//     icon: twitterSvg,
//   },
//   {
//     name: "Continue with Google",
//     href: "#",
//     icon: googleSvg,
//   },
// ];

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const [Message, SetMessage] = useState("");
  const navigate = useNavigate();
  const validationMessageRef = useRef<HTMLDivElement>(null);
  const [formData, setFormData] = useState({
    id: 0,
    firstName: "",
    lastName: "",
    emailAddress: "",
    nslType: "",
    companyName: "",
    branchLocation: "",
    password: "",
    confirmPassword: "",
    signUpNewsLetter: false,
    //recaptchaToken: "",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    nslType: "",
    companyName: "",
    branchLocation: "",
    password: "",
    confirmPassword: "",
  });

  const [isToggled, setIsToggled] = useState(true);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [captchaMsg, setcaptchaMsg] = useState("");
  const errorCaptchaMessageRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const handleCaptchaChange = (value: any) => {
    setCaptchaValue(value);
  };

  const handleInputChange = (e: any) => {
    const { id, value } = e.target;
    let errorMessage = "";

    switch (id) {
      case "firstName":
        errorMessage =
          value.trim() === "" ? "Your first name is required." : "";
        break;
      case "lastName":
        errorMessage = value.trim() === "" ? "Your last name is required." : "";
        break;
      case "emailAddress":
        errorMessage =
          value.trim() === ""
            ? "Your email address is required."
            : !/^\S+@\S+\.\S+$/.test(value)
            ? "Invalid email address."
            : "";
        break;
      case "companyName":
        errorMessage =
          value.trim() === ""
            ? "Your associated company name is required."
            : "";
        break;
      case "branchLocation":
        errorMessage =
          value.trim() === ""
            ? "Your company or branch location is required."
            : "";
        break;
      case "password":
        errorMessage =
          value.trim() === ""
            ? "A password for your account is required."
            : value.length < 6
            ? "Your password must be at least 6 characters."
            : "";
        break;
      case "confirmPassword":
        errorMessage =
          value !== formData.password ? "Your passwords do not match." : "";
        break;
      default:
        break;
    }

    setFormData({ ...formData, [id]: value });
    setErrors({ ...errors, [id]: errorMessage });
  };

  const handleSelectChange = (e: any) => {
    setFormData({ ...formData, nslType: e.target.value });
    if (e.target.value.trim() === "") {
      setErrors({
        ...errors,
        [e.target.id]: "Your industry role is required.",
      });
    } else {
      setErrors({
        ...errors,
        [e.target.id]: "",
      });
    }
  };

  const validateForm = () => {
    const newErrors: any = {};
    if (!formData.firstName.trim()) {
      newErrors.firstName = "Your first name is required.";
    }
    if (!formData.lastName.trim()) {
      newErrors.lastName = "Your last name is required.";
    }
    if (!formData.emailAddress.trim()) {
      newErrors.emailAddress = "An email address is required.";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.emailAddress)) {
      newErrors.emailAddress = "Invalid email address";
    }
    if (!formData.nslType.trim()) {
      newErrors.nslType = "Your industry role is required.";
    }
    if (!formData.companyName.trim()) {
      newErrors.companyName = "Your associated company name is required.";
    }
    if (!formData.branchLocation.trim()) {
      newErrors.branchLocation = "Your company or branch location is required.";
    }
    if (!formData.password.trim()) {
      newErrors.password = "A password for your account is required.";
    }
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Your passwords do not match.";
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length != 0 ? true : false;
  };
  // if (Object.keys(errors).length > 0) {
  //   console.log("errors",errors);
  //   return;
  // }
  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    var isValid = validateForm();
    if (isValid) {
      if (captchaValue == "" && captchaValue == null) {
        setcaptchaMsg(
          "Error from Google captcha or not a valid details entered"
        );
        scrollToError();
      }
      validationMessageRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      return;
    }
    try {
      // Send data to the API using Axios
      formData.signUpNewsLetter = isToggled;
      //formData.recaptchaToken = captchaValue ?? "";
      await axiosInstance
        .post(`SFRegister`, formData, {
          headers: {
            GRecAuthorization: `Bearer ${captchaValue}`,
          },
        })
        .then((response) => {
          if (response.data != "" && response.data == "Success") {
            navigate("/signup-email");
          } else {
            SetMessage(response.data);
            // Handle other statuses if needed
            console.error("Request failed with status:", response.data);
          }
        });
    } catch (error: any) {
      console.error("API Error:", error);
    }
  };

  const scrollToError = () => {
    if (errorCaptchaMessageRef.current) {
      errorCaptchaMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <div className={`nc-PageSignUp ${className}`} data-nc-id="PageSignUp">
      <HelmetComponent
        title={"Sign Up for CSC LED | Access Exclusive Lighting Solutions"}
        metaDescription="Sign up with CSC LED to gain access to our exclusive LED lighting solutions, product updates, and resources. Join our network and stay connected to industry-leading innovations."
        metaKeywords="CSC LED sign up, LED lighting access, Exclusive LED products, Sign up for CSC LED, LED lighting updates, Join CSC LED network, Access LED resources"
      />
      <div className="container mb-24 lg:mb-32" ref={validationMessageRef}>
        <h2 className="py-12 flex items-center text-3xl leading-[115%] md:text-4xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Sign Up for an Account
        </h2>
        <div className="sm:mx-5 mx-0 mt-10">
          <hr
            className="border-t border-gray-300"
            style={{ marginTop: "-2.5rem", marginBottom: "2.5rem" }}
          />
        </div>
        <div className="max-w-lg mx-auto space-y-6 ">
          {/* FORM */}

          {Message && (
            <div
              className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
              role="alert"
            >
              <span className="font-medium">{Message}</span>
            </div>
          )}
          <form
            className="grid grid-cols-1 gap-6"
            onSubmit={handleFormSubmit}
            method="post"
          >
            {captchaMsg && captchaMsg == null && (
              <div ref={errorCaptchaMessageRef} style={{ color: "#fd397a" }}>
                {captchaMsg}
              </div>
            )}

            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                First Name
              </span>
              <Input
                type="text"
                id="firstName"
                maxLength={50}
                className="mt-1"
                style={{
                  border:
                    errors.firstName && errors.firstName != ""
                      ? "1px solid #fd397a"
                      : "",
                }}
                onChange={handleInputChange}
                value={formData.firstName}
              />
              {errors.firstName && (
                <span style={{ color: "#fd397a" }}>{errors.firstName}</span>
              )}
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Last Name
              </span>
              <Input
                type="text"
                id="lastName"
                maxLength={50}
                className="mt-1"
                onChange={handleInputChange}
                style={{
                  border:
                    errors.lastName && errors.lastName != ""
                      ? "1px solid #fd397a"
                      : "",
                }}
                value={formData.lastName}
              />
              {errors.lastName && (
                <span style={{ color: "#fd397a" }}>{errors.lastName}</span>
              )}
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email Address
              </span>
              <Input
                type="email"
                id="emailAddress"
                className="mt-1"
                onChange={handleInputChange}
                style={{
                  border:
                    errors.emailAddress && errors.emailAddress != ""
                      ? "1px solid #fd397a"
                      : "",
                }}
                value={formData.emailAddress}
              />
              {errors.emailAddress && (
                <span style={{ color: "#fd397a" }}>{errors.emailAddress}</span>
              )}
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                What is your role in our amazing industry?
              </span>
              <Select
                id="nslType"
                className="mt-1.5"
                style={{
                  border:
                    errors.nslType && errors.nslType != ""
                      ? "1px solid #fd397a"
                      : "",
                }}
                value={formData.nslType}
                onChange={handleSelectChange}
              >
                <option value="">Select an option</option>
                <option value="Electrician">
                  I'm an electrician, contractor, or installer.
                </option>
                <option value="Buyer">
                  I'm a purchaser for a large organization.
                </option>
                <option value="Led">
                  I'm an LED wholesaler, distributor, or reseller.
                </option>
              </Select>
              {errors.nslType && (
                <span style={{ color: "#fd397a" }}>{errors.nslType}</span>
              )}
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Company Name
              </span>
              <Input
                type="text"
                id="companyName"
                maxLength={100}
                className="mt-1"
                onChange={handleInputChange}
                style={{
                  border:
                    errors.companyName && errors.companyName != ""
                      ? "1px solid #fd397a"
                      : "",
                }}
                value={formData.companyName}
              />
              {errors.companyName && (
                <span style={{ color: "#fd397a" }}>{errors.companyName}</span>
              )}
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Branch Location
              </span>
              <Input
                type="text"
                id="branchLocation"
                maxLength={50}
                placeholder="City, Province"
                style={{
                  border:
                    errors.branchLocation && errors.branchLocation != ""
                      ? "1px solid #fd397a"
                      : "",
                }}
                className="mt-1"
                onChange={handleInputChange}
                value={formData.branchLocation}
              />
              {errors.branchLocation && (
                <span style={{ color: "#fd397a" }}>
                  {errors.branchLocation}
                </span>
              )}
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
              </span>
              <Input
                id="password"
                type="password"
                className="mt-1"
                onChange={handleInputChange}
                style={{
                  border:
                    errors.password && errors.password != ""
                      ? "1px solid #fd397a"
                      : "",
                }}
                value={formData.password}
              />
              {errors.password && (
                <span style={{ color: "#fd397a" }}>{errors.password}</span>
              )}
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Confirm Password
              </span>
              <Input
                id="confirmPassword"
                type="password"
                className="mt-1"
                onChange={handleInputChange}
                value={formData.confirmPassword}
              />
              {errors.confirmPassword && (
                <span style={{ color: "#fd397a" }}>
                  {errors.confirmPassword}
                </span>
              )}
            </label>
            <div className="flex flex-col space-y-2">
              <label htmlFor="signUpNewsLetter">
                Would you like to sign up for our <strong>newsletter</strong>?
                It's chock-full of CSC LED updates to ensure you look smarter
                than your colleagues.
              </label>
              <ReactSwitch
                checked={isToggled}
                onChange={handleToggle}
                name="signUpNewsLetter"
              />
            </div>

            {formData.nslType != "" &&
              formData.nslType != "" &&
              formData.nslType == "Electrician" && (
                <div className="form-group">
                  <b>I acknowledge that:</b>
                  <div>
                    CSC LED only sells to wholesale distributor partners and
                    does not sell direct to contractors, electricians, or
                    installers. Pricing and inventory levels are subject to
                    change. Suggested trade pricing is the suggested price and
                    may vary. Please contact your preferred electrical
                    wholesaler for pricing, inventory verification, and shipping
                    ETAs.
                  </div>
                </div>
              )}
            <div className="form-group">
              <b className="block mb-2">I agree to the following:</b>
              <div className="text-sm">
                By submitting this form, I confirm that the information provided
                is accurate and understand that my credentials will be verified
                prior to being granted access to the CSC LED website. I agree to
                maintain the confidentiality of my login details, as well as the
                information obtained from the CSC LED website, and to not to
                share it with competitors of CSC LED or my retail customers.
                Please note that the approval process for sign up may take 24 to
                48 hours.
              </div>
            </div>
            <GoogleRecaptcha onChange={handleCaptchaChange} />

            <ButtonPrimary type="submit">Let's Do This!</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link className="text-csccolor" to="/login">
              Sign in
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
