import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import NcImage from "shared/NcImage/NcImage";
import { config } from "utils/Constance";
import axiosInstance from "api/AxiosInstance";
import ModalMemberBio from "./ModalMemberBio";
import Heading from "components/Heading/Heading";
import TeamCard from "./TeamCard";
import HelmetComponent from "components/HelmetComponent/HelmetComponent";

export interface OurTeamProps {
  className?: string;
}

export interface TeamMember {
  id: number;
  statusId: number;
  priority: number;
  employeeName: string;
  department: string;
  jobTitle: string;
  employeePictureUrl: string;
  employeePictureFileName: string;
  employeePictureUrl2: string | null;
  employeePictureFileName2: string | null;
  bioDescription: string;
  questionTitle1: string;
  questionAnswer1: string;
  questionTitle2: string;
  questionAnswer2: string;
  questionTitle3: string;
  questionAnswer3: string;
  isArchived: boolean;
  isDeleted: boolean;
  createdBy: number;
  createdOn: string;
  updateOn: string;
  updateBy: number;
}

interface TeamData {
  bannerImage: string;
  teamMembers: TeamMember[];
}

const OurTeam: FC<OurTeamProps> = ({ className = "" }) => {
  const [teamData, setTeamData] = useState<TeamData | null>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] =
    useState<TeamMember | null>(null);

  const displayTeamBio = (objId: number = 4) => {
    setIsOpenModal(true);
    const teamMember = teamData?.teamMembers.find(
      (member) => member.id === objId
    );
    if (teamMember) {
      setSelectedTeamMember(teamMember);
    }
  };

  const fetchData = async () => {
    const response = await axiosInstance
      .get("/Home/getMeetOurTeamList")
      .then((response) => {
        setTeamData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const skeleton = () => {
    return (
      <div
        role="status"
        className="p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700"
      >
        <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
          <svg
            className="w-10 h-10 text-gray-200 dark:text-gray-600"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 16 20"
          >
            <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
            <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
          </svg>
        </div>
        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        <span className="sr-only">Loading...</span>
      </div>
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={`nc-MeetOurTeam ${className}`} data-nc-id="MeetOurTeam">
      {/* Title & meta data */}
      <HelmetComponent
        title="Our Team | CSC LED - Your Lighting Solution Specialists"
        metaDescription="Meet the passionate experts behind CSC LED. Discover our team's skills, experience, and dedication to delivering exceptional commercial LED products. Learn more about the people driving our success."
        metaKeywords="CSC LED team, LED lighting experts, LED lighting solutions, Lighting design professionals, Experienced LED specialists, Sustainable lighting technologies, Customer-focused lighting service, LED manufacturing team, Professional lighting expertise"
      />
      <div className="container">
        <div className="pt-6 sm:pt-12 pb-16 lg:pb-28">
          <Heading
            className="items-center text-center mb-6 sm:mb-12 text-neutral-900 dark:text-neutral-50"
            isCenter={false}
          >
            Meet The CSC LED Team
          </Heading>
          {teamData ? (
            <>
              {teamData.bannerImage && (
                <NcImage
                  className="my-3 w-full rounded-xl"
                  src={`${config.url.AWS_S3_URL}${teamData.bannerImage}`}
                />
              )}
              {/**Team Members cards */} 
              <div className="grid grid-cols-2 gap-x-5 gap-y-8 md:grid-cols-3 lg:grid-cols-4 xl:gap-x-8">
                {teamData.teamMembers.map((item, index) => (
                  <TeamCard
                    item={item}
                    key={index}
                    openModal={displayTeamBio}
                  />
                ))}
              </div>
            </>
          ) : (
            skeleton()
          )}
        </div>
      </div>
      {/* MODAL VIEW ALL REVIEW */}
      <ModalMemberBio
        show={isOpenModal}
        onCloseModal={() => setIsOpenModal(false)}
        teamMember={selectedTeamMember}
      />
    </div>
  );
};

export default OurTeam;
