import Label from "components/Label/Label";
import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import packageReceived from "images/package-received.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useLocation } from "react-router-dom";
// import RadioGroup from "./RadioGroup";
import Input from "shared/Input/Input";
import axiosInstance from "api/AxiosInstance";
import { useNavigate } from "react-router-dom";
import Divider from "components/Divider";
import { RadioGroup } from "@headlessui/react";
// import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { useAppSelector } from "store/hooks";
import HeadingText1 from "components/HeadingText/HeadingText1";
import ButtonCSCLed from "shared/Button/ButtonCSCLed";

export interface OrderPlacedPageProps {
  className?: string;
}

function classNames(...classes: (string | undefined | null | false)[]): string {
  return classes.filter(Boolean).join(" ");
}

// radio button group for Free shipping code start //
const QuestionsSettings = [
  {
    name: "q1",
    item: "Amazing",
    value: "5",
    id: "Amazing",
    // description: "Shipping with Canada's best carriers as quickly as possible!",
    // deliveryMethod: "FreeShipping",
  },
  {
    name: "q1",
    item: "Good",
    value: "4",
    id: "Good",
  },
  {
    name: "q1",
    item: "Just ok",
    value: "3",
    id: "justok",
  },
  {
    name: "q1",
    item: "Bad",
    value: "2",
    id: "bad",
  },
  {
    name: "q1",
    item: "Awful",
    value: "1",
    id: "awful",
  },
  {
    name: "q1",
    item: "No Opinion",
    value: "6",
    id: "noOpinion",
  },
];


const OrderPlacedPage: FC<OrderPlacedPageProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { orderNumber } = location.state || {};
  
  const [selecteQuestionFeedBackMethod, setselecteQuestionFeedBackMethod] = useState(null);
  const [selecteQuestionFeedBackMethod2, setselecteQuestionFeedBackMethod2] = useState(null);
  const [selecteQuestionFeedBackMethod3, setselecteQuestionFeedBackMethod3] = useState(null);
  const [selecteQuestionFeedBackMethod4, setselecteQuestionFeedBackMethod4] = useState(null);
  const [selecteQuestionFeedBackMethod5, setselecteQuestionFeedBackMethod5] = useState(null);
  const user = useAppSelector((state: RootState) => state.auth);
  
  //const [emailAddress, setEmailAddress] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const EmailResponse = await axiosInstance.get(`/SFAccount`);
    const EmailResponsedata = EmailResponse.data.emailAddress;    
    setEmailaddressValue(EmailResponsedata);

    const firstNameLastName =
      EmailResponse.data.firstName + " " + EmailResponse.data.lastName;
    customerFeedBack.name = firstNameLastName;
    customerFeedBack.email = EmailResponsedata;
    customerFeedBack.contactEmail = EmailResponsedata;
    customerFeedBack.customerId = EmailResponse.data.customerConnectedId;
  };

  // Form data code
  const [customerFeedBack, setcustomerFeedBack] = useState({
    date: "",
    name: "",
    email: "",
    question1: 0,
    question1a: "",
    question1b: "",
    question2: 0,
    question2a: "",
    question2b: "",
    question3: 0,
    question3a: "",
    question3b: "",
    question4: 0,
    question4a: "",
    question4b: "",
    question5: 0,
    question5a: "",
    question5b: "",
    contactEmail: "",
    otherFeedback: "",
    customerId:0
  });

  const [errors, setErrors] = useState({
    selecteQuestionFeedBackMethod:"",
    selecteQuestionFeedBackMethod2:"",
    selecteQuestionFeedBackMethod3:"",
    selecteQuestionFeedBackMethod4:"",
    selecteQuestionFeedBackMethod5:"",
    setErrorEmailaddress:""
  });

  const [selectedOption, setSelectedOption] = useState<string>("");
  const [selectfeedBackbValue, setselectfeedBackbValue] = useState("");

  // Q1 Question 1
  const [selectQ1ChangeValue, setselectQ1ChangeValue] = useState("");
  const [selectQ1aValue, setselectQ1aValue] = useState("");
  const [hideQ1aValue, sethideQ1aValue] = useState(false);
  const [selectQ1bValue, setselectQ1bValue] = useState("");
  const [hideQ1bValue, sethideQ1bValue] = useState(false);
  const [feedbackReceived, sefeedbackReceived] = useState(false);

  const handleQ1OptionChange = (value: any) => {
   // console.log("Q1", value);
    setselectQ1ChangeValue(value);

    if (value === "1") {
      sethideQ1aValue(true);
    } else {
      sethideQ1aValue(false);
    }

    if (value === "5") {
      sethideQ1bValue(true);
    } else {
      sethideQ1bValue(false);
    }

    if (value === "6") {
      customerFeedBack.question1 = 0;
    } else {
      customerFeedBack.question1 = value;
    }
  };

  const handleSelectQ1aValue = (e: any) => {
    
    setselectQ1aValue(e.target.value);
    customerFeedBack.question1a = e.target.value;
  };

  const handleSelectQ1bValue = (e: any) => {
    setselectQ1bValue(e.target.value);
    customerFeedBack.question1b = e.target.value;
  };

  // Q2 Question 2
  const [selectQ2ChangeValue, setselectQ2ChangeValue] = useState("");
  const [selectQ2aValue, setselectQ2aValue] = useState("");
  const [hideQ2aValue, sethideQ2aValue] = useState(false);
  const [selectQ2bValue, setselectQ2bValue] = useState("");
  const [hideQ2bValue, sethideQ2bValue] = useState(false);

  const handleQ2OptionChange = (value: any) => {
  //  console.log("Q2", value);
    setselectQ2ChangeValue(value);
    if (value === "1") {
      sethideQ2aValue(true);
    } else {
      sethideQ2aValue(false);
    }

    if (value === "5") {
      sethideQ2bValue(true);
    } else {
      sethideQ2bValue(false);
    }

    if (value === "6") {
      customerFeedBack.question2 = 0;
    } else {
      customerFeedBack.question2 = value;
    }
  };

  const handleSelectQ2aValue = (e: any) => {
    setselectQ2aValue(e.target.value);
    customerFeedBack.question2a = e.target.value;
  };

  const handleSelectQ2bValue = (e: any) => {
    setselectQ2bValue(e.target.value);
    customerFeedBack.question2b = e.target.value;
  };

  // Q3 Question 3
  const [selectQ3ChangeValue, setselectQ3ChangeValue] = useState("");
  const [selectQ3aValue, setselectQ3aValue] = useState("");
  const [hideQ3aValue, sethideQ3aValue] = useState(false);
  const [selectQ3bValue, setselectQ3bValue] = useState("");
  const [hideQ3bValue, sethideQ3bValue] = useState(false);

  const handleQ3OptionChange = (value: any) => {
   // console.log("Q3", value);
    setselectQ3ChangeValue(value);
    if (value === "1") {
      sethideQ3aValue(true);
    } else {
      sethideQ3aValue(false);
    }

    if (value === "5") {
      sethideQ3bValue(true);
    } else {
      sethideQ3bValue(false);
    }

    if (value === "6") {
      customerFeedBack.question3 = 0;
    } else {
      customerFeedBack.question3 = value;
    }
  };

  const handleSelectQ3aValue = (e: any) => {
    setselectQ3aValue(e.target.value);
    customerFeedBack.question3a = e.target.value;
  };

  const handleSelectQ3bValue = (e: any) => {
    setselectQ3bValue(e.target.value);
    customerFeedBack.question3b = e.target.value;
  };

  // Q4 Question 4
  const [selectQ4ChangeValue, setselectQ4ChangeValue] = useState("");
  const [selectQ4aValue, setselectQ4aValue] = useState("");
  const [hideQ4aValue, sethideQ4aValue] = useState(false);
  const [selectQ4bValue, setselectQ4bValue] = useState("");
  const [hideQ4bValue, sethideQ4bValue] = useState(false);

  const handleQ4OptionChange = (value: any) => {
  //  console.log("Q3", value);
    setselectQ4ChangeValue(value);
    if (value === "1") {
      sethideQ4aValue(true);
    } else {
      sethideQ4aValue(false);
    }

    if (value === "5") {
      sethideQ4bValue(true);
    } else {
      sethideQ4bValue(false);
    }

    if (value === "6") {
      customerFeedBack.question3 = 0;
    } else {
      customerFeedBack.question3 = value;
    }
  };

  const handleSelectQ4aValue = (e: any) => {
    setselectQ4aValue(e.target.value);
    customerFeedBack.question4a = e.target.value;
  };

  const handleSelectQ4bValue = (e: any) => {
    setselectQ4bValue(e.target.value);
    customerFeedBack.question4b = e.target.value;
  };

  // Q5 Question 5
  const [selectQ5ChangeValue, setselectQ5ChangeValue] = useState("");
  const [selectQ5aValue, setselectQ5aValue] = useState("");
  const [hideQ5aValue, sethideQ5aValue] = useState(false);
  const [selectQ5bValue, setselectQ5bValue] = useState("");
  const [hideQ5bValue, sethideQ5bValue] = useState(false);

  const handleQ5OptionChange = (value: any) => {
    
    setselectQ4ChangeValue(value);
    if (value === "1") {
      sethideQ5aValue(true);
    } else {
      sethideQ5aValue(false);
    }

    if (value === "5") {
      sethideQ5bValue(true);
    } else {
      sethideQ5bValue(false);
    }

    if (value === "6") {
      customerFeedBack.question5 = 0;
    } else {
      customerFeedBack.question5 = value;
    }
  };

  const handleSelectQ5aValue = (e: any) => {
    setselectQ5aValue(e.target.value);
    customerFeedBack.question5a = e.target.value;
  };

  const handleSelectQ5bValue = (e: any) => {
    setselectQ5bValue(e.target.value);
    customerFeedBack.question5b = e.target.value;
  };

  // Function to handle onChange event and update selectedShippingMethod state
  const handleSelectfeedbackValue = (e: any) => {
   // console.log("=====>handleSelectfeedbackValue", e.target.value);
    setselectfeedBackbValue(e.target.value);
    customerFeedBack.otherFeedback = e.target.value;
  };

  // Function to handle onChange event and update selectedShippingMethod state
  const [setEmailaddress, setEmailaddressValue] = useState("");
  const handleEmailInputChange = (e: any) => {
   // console.log("=====>handleEmailInputChange", e.target.value);
    setEmailaddressValue(e.target.value);
    customerFeedBack.contactEmail = e.target.value;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    var isValid = validateCustomerFeedBack();

    if(isValid){
    } else{
       // Call the onSubmit function from props with the form data
    handleFormSubmit(customerFeedBack);
    }
 
  };

  const validateCustomerFeedBack = () => {
    
    const newErrors: any = {};
    console.log("selecteQuestionFeedBackMethod",selecteQuestionFeedBackMethod);
    if (selecteQuestionFeedBackMethod === null) {
       newErrors.selecteQuestionFeedBackMethod = "A website rating is required to submit the feedback form."

        // Select the div you want to scroll to
      const targetDiv = document.getElementById("scroll_div"); 
      if (targetDiv) {
      const divTopPosition =
      targetDiv.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
       top: divTopPosition,
       behavior: "smooth", // Smooth scroll
     });
    }
      
    }
    if (selecteQuestionFeedBackMethod2 === null) {
      newErrors.selecteQuestionFeedBackMethod2 = "A customer service rating is required to submit the feedback form."
         // Select the div you want to scroll to
         const targetDiv = document.getElementById("scroll_div"); 
         if (targetDiv) {
         const divTopPosition =
         targetDiv.getBoundingClientRect().top + window.scrollY;
         window.scrollTo({
          top: divTopPosition,
          behavior: "smooth", // Smooth scroll
        });
       }
   }
      if (selecteQuestionFeedBackMethod3 === null) {
        newErrors.selecteQuestionFeedBackMethod3 = "A technical support rating is required to submit the feedback form."
           // Select the div you want to scroll to
      const targetDiv = document.getElementById("scroll_div"); 
      if (targetDiv) {
      const divTopPosition =
      targetDiv.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
       top: divTopPosition,
       behavior: "smooth", // Smooth scroll
     });
    }
    }
    if (selecteQuestionFeedBackMethod4 === null) {
      newErrors.selecteQuestionFeedBackMethod4 = "A product quality rating is required to submit the feedback form."
         // Select the div you want to scroll to
         const targetDiv = document.getElementById("scroll_div"); 
         if (targetDiv) {
         const divTopPosition =
         targetDiv.getBoundingClientRect().top + window.scrollY;
         window.scrollTo({
          top: divTopPosition,
          behavior: "smooth", // Smooth scroll
        });
       }
    }
    if (selecteQuestionFeedBackMethod5 === null) {
      newErrors.selecteQuestionFeedBackMethod5 = "A product price rating is required to submit the feedback form."

          // Select the div you want to scroll to
          const targetDiv = document.getElementById("scroll_div2"); 
          if (targetDiv) {
          const divTopPosition =
          targetDiv.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({
           top: divTopPosition,
           behavior: "smooth", // Smooth scroll
         });
        }
    }
    if(setEmailaddress === ""){
      newErrors.setErrorEmailaddress = "An email address is required."

       // Select the div you want to scroll to
       const targetDiv = document.getElementById("scroll_div2"); 
       if (targetDiv) {
       const divTopPosition =
       targetDiv.getBoundingClientRect().top + window.scrollY;
       window.scrollTo({
        top: divTopPosition,
        behavior: "smooth", // Smooth scroll
      });
     }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length !== 0 ? true : false;   
  
  }

  const handleFormSubmit = async (data: any) => {
   // console.log("===>customerFeedBack", customerFeedBack);
    setcustomerFeedBack(customerFeedBack);
    await axiosInstance
      .post(`/Cart/SaveCustomerFeedbackByUserId`, customerFeedBack)
      .then((response) => {
        //console.log("hello");
       // console.log("response", response.data);
        if (response.data === true) {
          sefeedbackReceived(true);
        } else {
          sefeedbackReceived(false);
        }
      });
  };


  // Function to handle onChange event and update selectedShippingMethod state
  const handleFeedBackChange = (e: any) => {
    setselecteQuestionFeedBackMethod(e); 

    if (e.value === "1") {
      sethideQ1aValue(true);
    } else {
      sethideQ1aValue(false);
    }

    if (e.value === "5") {
      sethideQ1bValue(true);
    } else {
      sethideQ1bValue(false);
    }

    if (e.value === "6") {
      customerFeedBack.question1 = 0;
    } else {
      customerFeedBack.question1 = e.value;
    }
  };

  // Function to handle onChange event and update selectedShippingMethod state
  const handleFeedBackChange2 = (e: any) => {
    setselecteQuestionFeedBackMethod2(e); 

    if (e.value === "1") {
      sethideQ2aValue(true);
    } else {
      sethideQ2aValue(false);
    }

    if (e.value === "5") {
      sethideQ2bValue(true);
    } else {
      sethideQ2bValue(false);
    }

    if (e.value === "6") {
      customerFeedBack.question2 = 0;
    } else {
      customerFeedBack.question2 = e.value;
    }
  };

  // Function to handle onChange event and update selectedShippingMethod state
  const handleFeedBackChange3 = (e: any) => {
    setselecteQuestionFeedBackMethod3(e); 

    if (e.value === "1") {
      sethideQ3aValue(true);
    } else {
      sethideQ3aValue(false);
    }

    if (e.value === "5") {
      sethideQ3bValue(true);
    } else {
      sethideQ3bValue(false);
    }

    if (e.value === "6") {
      customerFeedBack.question3 = 0;
    } else {
      customerFeedBack.question3 = e.value;
    }
  };

  // Function to handle onChange event and update selectedShippingMethod state
  const handleFeedBackChange4 = (e: any) => {
    setselecteQuestionFeedBackMethod4(e); 

    if (e.value === "1") {
      sethideQ4aValue(true);
    } else {
      sethideQ4aValue(false);
    }

    if (e.value === "5") {
      sethideQ4bValue(true);
    } else {
      sethideQ4bValue(false);
    }

    if (e.value === "6") {
      customerFeedBack.question4 = 0;
    } else {
      customerFeedBack.question4 = e.value;
    }
  };

  // Function to handle onChange event and update selectedShippingMethod state
  const handleFeedBackChange5 = (e: any) => {
    setselecteQuestionFeedBackMethod5(e); 

    if (e.value === "1") {
      sethideQ5aValue(true);
    } else {
      sethideQ5aValue(false);
    }

    if (e.value === "5") {
      sethideQ5bValue(true);
    } else {
      sethideQ5bValue(false);
    }

    if (e.value === "6") {
      customerFeedBack.question5 = 0;
    } else {
      customerFeedBack.question5 = e.value;
    }
  };


  return (
    <div
      className={`nc-OrderPlacedPage ${className}`}
      data-nc-id="OrderPlacedPage"
    >
      <div>
        <div>
          <Helmet>
            <title> Order Confirmation | CSC LED</title>
          </Helmet>
          {/* <CommonLayout>             */}
          <main className="container py-16 lg:pb-28 lg:pt-20 ">
            <div className="mb-4 sm:mb-4">
            <HeadingText1 className="text-csccolor">Order Confirmation</HeadingText1>              
            </div>
            <Divider/>
            <div>
              <div className="block mt-5">
                <div className="flex items-center">
                  <img
                    src={packageReceived}
                    alt={`packageReceived`}
                    className="mr-3 h-10 w-10 object-contain object-center"
                  />
                  <p className="text-black">
                    Your order has been received. <br></br>
                    <b>Order Number: </b>
                    {orderNumber}
                  </p>
                </div>
                <div className="flex items-center mt-5">
                  <p className="text-black">
                    We are sending you an <b>email confirmation</b> for your order now.
                    <br></br>Thank you for supporting CSC LED.
                  </p>
                </div>
                <div className="flex flex-col sm:flex-row w-full md:w-100 sm:w-full mt-5">
                  {/* <ButtonPrimary
                    className="capitalize sm:!px-7 shadow-none"
                    href="/home"
                  >
                    continue browsing
                  </ButtonPrimary> */}
                </div>
              </div>
            </div>
            <div className="mt-10 block mb-6">

            </div>
            {/* <div className="border-t border-slate-200 dark:border-slate-700 mt-6 block mb-6"></div> */}
            {/* <div className="border my-6 border-slate-200 dark:border-slate-700 rounded-xl "> */}
            

            {feedbackReceived === false ? (
              <form onSubmit={handleSubmit}>
                <div className="mb-4 sm:mb-4">
                <HeadingText1 className="text-csccolor">We Want Your Feedback!</HeadingText1>                              
                </div>
                <Divider/>
                <p className="text-gray-900 text-lg font-medium mt-5">
                  Tell us how we did to enter a monthly{" "}
                  <span className="text-red-600">$25 Tim Hortons gift card </span>
                  draw!
                </p>
                <div className="block">
                  <div className="flex items-center mt-5">
                    <p className="text-black">
                      Customer service is incredibly important to us! Your input
                      shapes our business and <b>enables us to make your life
                      easier</b>, so please do not hesitate to lay it on us.
                      <br></br>
                    </p>
                  </div>
                  <div className="flex items-center mt-5">
                    <p className="text-black">
                      In an effort to compensate you for your time, we run a
                      monthly $25 Tim Hortons gift card draw for any form
                      submissions received below. Should you win, we will reach
                      out to you using the email you provide below. The winner
                      will be drawn randomly at the <b>beginning of each month</b>.
                      <br></br>
                    </p>
                  </div>
                  <div className="flex items-center mt-5" id="scroll_div">
                    <p className="text-black">
                      Thank you to everyone who continues to help us become a
                      better business.
                    </p>
                  </div>

                  <div className="w-full mt-6">
                    <Label className="block text-gray-700 text-base mb-2">
                       1) How would you rate our website?
                      </Label>
                      </div>   
                  <div className="flex w-full mt-3">  
                    <RadioGroup
                      value={selecteQuestionFeedBackMethod}
                       onChange={handleFeedBackChange}
                    >  
                    <RadioGroup.Label className="sr-only">
                        Privacy setting
                      </RadioGroup.Label>                   
                      {/* <div className={`grid grid-cols-${QuestionsSettings.length} gap-5 rounded-md bg-white`}> */}
                      <div className="grid grid-cols-2 xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-5 rounded-md bg-white">

                        {QuestionsSettings.map(
                          (setting, settingIdx) => (
                            <RadioGroup.Option
                              key={setting.item}
                              value={setting}
                              className={({ checked }) =>
                                classNames(
                                  settingIdx === 0
                                    ? "rounded-md"
                                    : "rounded-md",
                                  settingIdx ===
                                  QuestionsSettings.length - 1
                                    ? "rounded-md"
                                    : "",
                                  checked
                                    ? "z-10 border-indigo-200 bg-indigo-50"
                                    : "border-slate-200",
                                  "justify-center relative flex items-center cursor-pointer border p-3 focus:outline-none"
                                )
                              }
                            >
                              {({ active, checked }) => (
                                <>
                                  {/* <span
                                    className={classNames(
                                      checked
                                        ? "bg-indigo-600 border-transparent"
                                        : "bg-white border-slate-300",
                                      active
                                        ? "ring-2 ring-offset-2 ring-indigo-600"
                                        : "",
                                      "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                    )}
                                    aria-hidden="true"
                                  >
                                    <span className="rounded-full bg-white w-1.5 h-1.5" />
                                  </span> */}
                                  <span className="flex flex-col">
                                    <div className="flex w-full justify-between">
                                      <RadioGroup.Label
                                        as="span"
                                        className={classNames(
                                          checked
                                            ? "text-indigo-900"
                                            : "text-gray-900",
                                          "block text-base font-medium"
                                        )}
                                      >
                                       {setting.item}
                                      </RadioGroup.Label>                                      
                                    </div>                                  
                                  </span>
                                </>
                              )}
                            </RadioGroup.Option>
                          )
                        )}
                      </div>
                    </RadioGroup>
                       
                  </div>
                  {errors.selecteQuestionFeedBackMethod && (
                        <span style={{ color: "#fd397a" }}>{errors.selecteQuestionFeedBackMethod}</span>
                      )}
                  {hideQ1aValue ? (
                    <div className="form-group">
                      <div className="w-[755px] mt-6">
                        <label className="block text-gray-700 text-base mb-2">
                          That's okay, we can handle it, but could you tell us a
                          bit more about why so we can fix it for you?
                        </label>
                        <textarea
                          id="1a"
                          name="1a"
                          className="form-control border rounded-md focus:outline-none focus:ring-1  w-full px-4 py-2 text-base leading-6 text-gray-700 placeholder-gray-400 bg-white border-slate-200 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          maxLength={300}
                          rows={4}
                          cols={80}
                          spellCheck={false}
                          value={selectQ1aValue}
                          onChange={handleSelectQ1aValue}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {hideQ1bValue ? (
                    <div className="form-group">
                      <div className="w-[755px] mt-6">
                        <label className="block text-gray-700 text-base mb-2">
                          You rated our website as amazing! Would you mind
                          telling us what you liked so much, so we can focus on
                          making it even better for you?
                        </label>
                        <textarea
                          id="1a"
                          name="1a"
                          className="form-control border rounded-md focus:outline-none focus:ring-1  w-full px-4 py-2 text-base leading-6 text-gray-700 placeholder-gray-400 bg-white border-slate-200 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          maxLength={300}
                          rows={4}
                          cols={80}
                          spellCheck={false}
                          value={selectQ1bValue}
                          onChange={handleSelectQ1bValue}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* 2nd Question   */}
                  <div className="w-full mt-6" id="scroll_div">
                    <Label className="block text-gray-700 text-base mb-2">
                       2) How would you rate our customer service?
                      </Label>
                      </div>   
                  <div className="flex w-full mt-3">  
                    <RadioGroup
                      value={selecteQuestionFeedBackMethod2}
                       onChange={handleFeedBackChange2}
                    >                     
                      <div className="grid grid-cols-2 xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-5 rounded-md bg-white">
                        {QuestionsSettings.map(
                          (setting, settingIdx) => (
                            <RadioGroup.Option
                              key={setting.item}
                              value={setting}
                              className={({ checked }) =>
                                classNames(
                                  settingIdx === 0
                                    ? "rounded-md"
                                    : "rounded-md",
                                  settingIdx ===
                                  QuestionsSettings.length - 1
                                    ? "rounded-md"
                                    : "",
                                  checked
                                    ? "z-10 border-indigo-200 bg-indigo-50"
                                    : "border-slate-200",
                                  "justify-center relative flex items-center cursor-pointer border p-3 focus:outline-none"
                                )
                              }
                            >
                              {({ active, checked }) => (
                                <>
                                  {/* <span
                                    className={classNames(
                                      checked
                                        ? "bg-indigo-600 border-transparent"
                                        : "bg-white border-slate-300",
                                      active
                                        ? "ring-2 ring-offset-2 ring-indigo-600"
                                        : "",
                                      "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                    )}
                                    aria-hidden="true"
                                  >
                                    <span className="rounded-full bg-white w-1.5 h-1.5" />
                                  </span> */}
                                  <span className="flex flex-col">
                                    <div className="flex w-full justify-between">
                                      <RadioGroup.Label
                                        as="span"
                                        className={classNames(
                                          checked
                                            ? "text-indigo-900"
                                            : "text-gray-900",
                                          "block text-base font-medium"
                                        )}
                                      >
                                       {setting.item}
                                      </RadioGroup.Label>                                      
                                    </div>                                  
                                  </span>
                                </>
                              )}
                            </RadioGroup.Option>
                          )
                        )}
                      </div>
                    </RadioGroup>
                  </div>
                  {errors.selecteQuestionFeedBackMethod2 && (
                        <span style={{ color: "#fd397a" }}>{errors.selecteQuestionFeedBackMethod2}</span>
                      )}
                  {hideQ2aValue ? (
                    <div className="form-group">
                      <div className="w-[755px] mt-6">
                        <label className="block text-gray-700 text-base mb-2">
                          That's okay, we can handle it, but could you tell us a
                          bit more about why so we can fix it for you?
                        </label>
                        <textarea
                          id="1a"
                          name="1a"
                          className="form-control border rounded-md focus:outline-none focus:ring-1  w-full px-4 py-2 text-base leading-6 text-gray-700 placeholder-gray-400 bg-white border-slate-200 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          maxLength={300}
                          rows={4}
                          cols={80}
                          spellCheck={false}
                          value={selectQ2aValue}
                          onChange={handleSelectQ2aValue}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {hideQ2bValue ? (
                    <div className="form-group">
                      <div className="w-[755px] mt-6">
                        <label className="block text-gray-700 text-base mb-2">
                          You rated our customer service as amazing! 
                          Would you mind telling us why you had such a good customer service experience? 
                          We like to make sure credit goes to where it's due.
                        </label>
                        <textarea
                          id="1a"
                          name="1a"
                          className="form-control border rounded-md focus:outline-none focus:ring-1  w-full px-4 py-2 text-base leading-6 text-gray-700 placeholder-gray-400 bg-white border-slate-200 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          maxLength={300}
                          rows={4}
                          cols={80}
                          spellCheck={false}
                          value={selectQ2bValue}
                          onChange={handleSelectQ2bValue}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}


{/* Question 3 */}

<div className="w-full mt-6" id="scroll_div">
                    <Label className="block text-gray-700 text-base mb-2">
                    3) How would you rate our technical support?
                      </Label>
                      </div>   
                  <div className="flex w-full mt-3">  
                    <RadioGroup
                      value={selecteQuestionFeedBackMethod3}
                       onChange={handleFeedBackChange3}
                    >                     
                      <div className="grid grid-cols-2 xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-5 rounded-md bg-white">
                        {QuestionsSettings.map(
                          (setting, settingIdx) => (
                            <RadioGroup.Option
                              key={setting.item}
                              value={setting}
                              className={({ checked }) =>
                                classNames(
                                  settingIdx === 0
                                    ? "rounded-md"
                                    : "rounded-md",
                                  settingIdx ===
                                  QuestionsSettings.length - 1
                                    ? "rounded-md"
                                    : "",
                                  checked
                                    ? "z-10 border-indigo-200 bg-indigo-50"
                                    : "border-slate-200",
                                  "justify-center relative flex items-center cursor-pointer border p-3 focus:outline-none"
                                )
                              }
                            >
                              {({ active, checked }) => (
                                <>
                                  {/* <span
                                    className={classNames(
                                      checked
                                        ? "bg-indigo-600 border-transparent"
                                        : "bg-white border-slate-300",
                                      active
                                        ? "ring-2 ring-offset-2 ring-indigo-600"
                                        : "",
                                      "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                    )}
                                    aria-hidden="true"
                                  >
                                    <span className="rounded-full bg-white w-1.5 h-1.5" />
                                  </span> */}
                                  <span className="flex flex-col">
                                    <div className="flex w-full justify-between">
                                      <RadioGroup.Label
                                        as="span"
                                        className={classNames(
                                          checked
                                            ? "text-indigo-900"
                                            : "text-gray-900",
                                          "block text-base font-medium"
                                        )}
                                      >
                                       {setting.item}
                                      </RadioGroup.Label>                                      
                                    </div>                                  
                                  </span>
                                </>
                              )}
                            </RadioGroup.Option>
                          )
                        )}
                      </div>
                    </RadioGroup>
                  </div>
                  {errors.selecteQuestionFeedBackMethod3 && (
                        <span style={{ color: "#fd397a" }}>{errors.selecteQuestionFeedBackMethod3}</span>
                      )}
                  {hideQ3aValue ? (
                    <div className="form-group">
                      <div className="w-[755px] mt-6">
                        <label className="block text-gray-700 text-base mb-2">
                          That's okay, we can handle it, but could you tell us a
                          bit more about why so we can fix it for you?
                        </label>
                        <textarea
                          id="1a"
                          name="1a"
                          className="form-control border rounded-md focus:outline-none focus:ring-1  w-full px-4 py-2 text-base leading-6 text-gray-700 placeholder-gray-400 bg-white border-slate-200 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          maxLength={300}
                          rows={4}
                          cols={80}
                          spellCheck={false}
                          value={selectQ3aValue}
                          onChange={handleSelectQ3aValue}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {hideQ3bValue ? (
                    <div className="form-group">
                      <div className="w-[755px] mt-6">
                        <label className="block text-gray-700 text-base mb-2">
                        You rated our technical support as amazing! Would you mind telling us why you had such a great technical support experience? We like to make sure credit goes to where it's due.
                        </label>
                        <textarea
                          id="1a"
                          name="1a"
                          className="form-control border rounded-md focus:outline-none focus:ring-1  w-full px-4 py-2 text-base leading-6 text-gray-700 placeholder-gray-400 bg-white border-slate-200 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          maxLength={300}
                          rows={4}
                          cols={80}
                          spellCheck={false}
                          value={selectQ3bValue}
                          onChange={handleSelectQ3bValue}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

 {/* QUestion 4 */}
 <div className="w-full mt-6" id="scroll_div"> 
                    <Label className="block text-gray-700 text-base mb-2">
                    4) How would you rate the quality of our products?
                      </Label>
                      </div>   
                  <div className="flex w-full mt-3">  
                    <RadioGroup
                      value={selecteQuestionFeedBackMethod4}
                       onChange={handleFeedBackChange4}
                    >                     
                      <div className="grid grid-cols-2 xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-5 rounded-md bg-white">
                        {QuestionsSettings.map(
                          (setting, settingIdx) => (
                            <RadioGroup.Option
                              key={setting.item}
                              value={setting}
                              className={({ checked }) =>
                                classNames(
                                  settingIdx === 0
                                    ? "rounded-md"
                                    : "rounded-md",
                                  settingIdx ===
                                  QuestionsSettings.length - 1
                                    ? "rounded-md"
                                    : "",
                                  checked
                                    ? "z-10 border-indigo-200 bg-indigo-50"
                                    : "border-slate-200",
                                  "justify-center relative flex items-center cursor-pointer border p-3 focus:outline-none"
                                )
                              }
                            >
                              {({ active, checked }) => (
                                <>
                                  {/* <span
                                    className={classNames(
                                      checked
                                        ? "bg-indigo-600 border-transparent"
                                        : "bg-white border-slate-300",
                                      active
                                        ? "ring-2 ring-offset-2 ring-indigo-600"
                                        : "",
                                      "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                    )}
                                    aria-hidden="true"
                                  >
                                    <span className="rounded-full bg-white w-1.5 h-1.5" />
                                  </span> */}
                                  <span className="flex flex-col">
                                    <div className="flex w-full justify-between">
                                      <RadioGroup.Label
                                        as="span"
                                        className={classNames(
                                          checked
                                            ? "text-indigo-900"
                                            : "text-gray-900",
                                          "block text-base font-medium"
                                        )}
                                      >
                                       {setting.item}
                                      </RadioGroup.Label>                                      
                                    </div>                                  
                                  </span>
                                </>
                              )}
                            </RadioGroup.Option>
                          )
                        )}
                      </div>
                    </RadioGroup>
                  </div>
                  {errors.selecteQuestionFeedBackMethod4 && (
                        <span style={{ color: "#fd397a" }}>{errors.selecteQuestionFeedBackMethod4}</span>
                      )}
                  {hideQ4aValue ? (
                    <div className="form-group">
                      <div className="w-[755px] mt-6">
                        <label className="block text-gray-700 text-base mb-2">
                          That's okay, we can handle it, but could you tell us a
                          bit more about why so we can fix it for you?
                        </label>
                        <textarea
                          id="1a"
                          name="1a"
                          className="form-control border rounded-md focus:outline-none focus:ring-1  w-full px-4 py-2 text-base leading-6 text-gray-700 placeholder-gray-400 bg-white border-slate-200 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          maxLength={300}
                          rows={4}
                          cols={80}
                          spellCheck={false}
                          value={selectQ4aValue}
                          onChange={handleSelectQ4aValue}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {hideQ4bValue ? (
                    <div className="form-group">
                      <div className="w-[755px] mt-6">
                        <label className="block text-gray-700 text-base mb-2">
                         You rated our products' quality as amazing! Would you mind telling us what you like about them so much? We want to make sure we keep it up!
                        </label>
                        <textarea
                          id="1a"
                          name="1a"
                          className="form-control border rounded-md focus:outline-none focus:ring-1  w-full px-4 py-2 text-base leading-6 text-gray-700 placeholder-gray-400 bg-white border-slate-200 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          maxLength={300}
                          rows={4}
                          cols={80}
                          spellCheck={false}
                          value={selectQ4bValue}
                          onChange={handleSelectQ4bValue}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

 {/* Question 5  */}
 <div className="w-full mt-6" id="scroll_div1">
                    <Label className="block text-gray-700 text-base mb-2">
                    5) How would you rate our products pricing?
                      </Label>
                      </div>   
                  <div className="flex w-full mt-3">  
                    <RadioGroup
                      value={selecteQuestionFeedBackMethod5}
                       onChange={handleFeedBackChange5}
                    >                     
                      <div className="grid grid-cols-2 xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-5 rounded-md bg-white">
                        {QuestionsSettings.map(
                          (setting, settingIdx) => (
                            <RadioGroup.Option
                              key={setting.item}
                              value={setting}
                              className={({ checked }) =>
                                classNames(
                                  settingIdx === 0
                                    ? "rounded-md"
                                    : "rounded-md",
                                  settingIdx ===
                                  QuestionsSettings.length - 1
                                    ? "rounded-md"
                                    : "",
                                  checked
                                    ? "z-10 border-indigo-200 bg-indigo-50"
                                    : "border-slate-200",
                                  "justify-center relative flex items-center cursor-pointer border p-3 focus:outline-none"
                                )
                              }
                            >
                              {({ active, checked }) => (
                                <>
                                  {/* <span
                                    className={classNames(
                                      checked
                                        ? "bg-indigo-600 border-transparent"
                                        : "bg-white border-slate-300",
                                      active
                                        ? "ring-2 ring-offset-2 ring-indigo-600"
                                        : "",
                                      "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                    )}
                                    aria-hidden="true"
                                  >
                                    <span className="rounded-full bg-white w-1.5 h-1.5" />
                                  </span> */}
                                  <span className="flex flex-col">
                                    <div className="flex w-full justify-between">
                                      <RadioGroup.Label
                                        as="span"
                                        className={classNames(
                                          checked
                                            ? "text-indigo-900"
                                            : "text-gray-900",
                                          "block text-base font-medium"
                                        )}
                                      >
                                       {setting.item}
                                      </RadioGroup.Label>                                      
                                    </div>                                  
                                  </span>
                                </>
                              )}
                            </RadioGroup.Option>
                          )
                        )}
                      </div>
                    </RadioGroup>
                  </div>
                  {errors.selecteQuestionFeedBackMethod5 && (
                        <span style={{ color: "#fd397a" }}>{errors.selecteQuestionFeedBackMethod5}</span>
                      )}
                  {hideQ5aValue ? (
                    <div className="form-group">
                      <div className="w-[755px] mt-6">
                        <label className="block text-gray-700 text-base mb-2">
                          That's okay, we can handle it, but could you tell us a
                          bit more about why so we can fix it for you?
                        </label>
                        <textarea
                          id="1a"
                          name="1a"
                          className="form-control border rounded-md focus:outline-none focus:ring-1  w-full px-4 py-2 text-base leading-6 text-gray-700 placeholder-gray-400 bg-white border-slate-200 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          maxLength={300}
                          rows={4}
                          cols={80}
                          spellCheck={false}
                          value={selectQ5aValue}
                          onChange={handleSelectQ5aValue}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {hideQ5bValue ? (
                    <div className="form-group">
                      <div className="w-[755px] mt-6">
                        <label className="block text-gray-700 text-base mb-2">
                        You rated our products pricing as amazing! Would you mind telling us which product we're crushing the competition with? We want to make sure we keep it up!
                        </label>
                        <textarea
                          id="1a"
                          name="1a"
                          className="form-control border rounded-md focus:outline-none focus:ring-1  w-full px-4 py-2 text-base leading-6 text-gray-700 placeholder-gray-400 bg-white border-slate-200 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          maxLength={300}
                          rows={4}
                          cols={80}
                          spellCheck={false}
                          value={selectQ5bValue}
                          onChange={handleSelectQ5bValue}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  
{/* End Question  */}                  

                  <div className="form-group mt-6">
                    <div className="w-[755px]">
                      <Label className="blocktext-base mb-2">
                        Do you have any other feedback or suggestions you would
                        be willing to share?
                      </Label>
                      <textarea
                        id="1a"
                        name="1a"
                        className="mt-1.5 form-control border rounded-md focus:outline-none focus:ring-1  w-full px-4 py-2 text-base leading-6 text-gray-700 placeholder-gray-400 bg-white border-slate-200 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        maxLength={300}
                        rows={4}
                        cols={80}
                        spellCheck={false}
                        value={selectfeedBackbValue}
                        onChange={handleSelectfeedbackValue}
                      />
                    </div>
                  </div>

                  <div className="form-group mt-6" id="scroll_div1">
                    <div className="w-[755px]">
                      <Label>
                        Please provide an email to which we can use to contact
                        you, should you win the draw:
                      </Label>
                      <Input
                        className="mt-1.5"
                        type="text"
                        name="emailAddress"
                        value={setEmailaddress}
                        onChange={handleEmailInputChange}
                      />
                    </div>
                  </div>
                  {errors.setErrorEmailaddress && (
                        <span style={{ color: "#fd397a" }}>{errors.setErrorEmailaddress}</span>
                      )}

                  <div className="w-[755px] flex items-center mt-6">
                    <p className="text-black">
                      <b>Please note:</b> Your name and email which you provide
                      in this survey will remain strictly confidential, and will
                      not be shared with any third parties.
                    </p>
                  </div>
                  <div className="mt-6 flex flex-col sm:flex-row w-full md:w-100 sm:w-full">
                    <ButtonCSCLed
                      type="submit"
                      className="capitalize sm:!px-7 shadow-none"
                    >
                      send feedback
                    </ButtonCSCLed>
                  </div>
                </div>
                {/* </div> */}
              </form>
            ) : (
              <div className="block mt-5">
                 <div className="mb-4 sm:mb-4">
                 <HeadingText1 className="text-csccolor">Feedback Received!</HeadingText1>                  
                </div>
                <Divider/>
                {/* <div className="flex items-center">
                  <i className="text-4xl object-contain object-center fi fi-rr-hand-spock mr-3"></i>
                  <p className="text-black">
                    <h4>
                      <b>Feedback Received!</b>
                    </h4>{" "}
                    You Rock!
                  </p>
                </div> */}
                <div className="flex items-center mt-5">
                  <p className="text-black">
                    Thank you for taking the time to help us serve you better.
                    <br></br>
                    <br></br>We will be in touch at the <b>start of next month </b>
                     should you win the draw!
                  </p>
                </div>
                <div className="flex items-center mt-5">
                  <p className="text-black">
                    Thank you,<br></br>
                    CSC LED Team
                  </p>
                </div>
              </div>
            )}
            
          </main>
        </div>
      </div>
    </div>
  );
};

export default OrderPlacedPage;
