import React, { createContext, useContext, useEffect, useState } from 'react';
import bgImage from '../images/background-images/DarkSkyWebpage-BackgroundImage.jpg'

export interface BackgroundContextProps{
  isBackgroundImage:boolean;
  setBackgroundImage: (value: boolean) => void;
}

export const BackgroundContext = createContext<BackgroundContextProps | undefined>(undefined);

export const useBackgroundContext = () => useContext(BackgroundContext);

export const BackgroundProvider: React.FC<{ children: React.ReactNode}> = ({
  children,
}) => {

  const [isBackgroundImage, setBackgroundImage] = useState(false);

  return (
    <BackgroundContext.Provider value={{ isBackgroundImage,setBackgroundImage }}>
      <div
        className={`bg-white text-base dark:bg-slate-900 text-slate-900 dark:text-slate-200 halloween:bg-orange-400 halloween:text-black ${
          isBackgroundImage ? 'bg-cover bg-center' : ''
        }`}
        style={
          isBackgroundImage
            ? { backgroundImage: `url(${bgImage})` }
            : undefined
        }
      >
        {children}
      </div>
    </BackgroundContext.Provider>
  );
};
