import Label from "components/Label/Label";
import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet-async";
import ReactSwitch from "react-switch";
import axiosInstance from "api/AxiosInstance";
import Tooltip from "components/Tooltip";
import tooltipImage from "../../images/tooltipIcon.png";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import PhoneNumberMask from "shared/PhoneNumberMask/PhoneNumberMask";
import { RootState } from "store/store";
import { useAppSelector } from "store/hooks";
import ModalAccountPopup from "containers/ShippingAddressPage/ModalAccountPopup";

export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const user = useAppSelector((state: RootState) => state.auth);

  const [selectedOption, setSelectedOption] = useState<any>("");
  const [isToggled, setIsToggled] = useState(false);
  // const [show, setShow] = useState(true);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const [address, setAddress] = useState<any>("");
  const [country, setCountry] = useState<any>("");
  const [city, setCity] = useState<any>("");
  const [postcode, setPostcode] = useState<any>("");
  const [province, setProvince] = useState<any>("");
  const [phoneNumber, setPhoneNumber] = useState<any>("");
  const [address2, setAddress2] = useState<any>("");
  const [isModalAccountSave, setIsModalAccountSave] = useState(false);
  const [accountSaveMessage, setaccountSaveMessage] = useState<any>("");

  const onModalClose = () => setIsModalAccountSave(false);
 
  const handleSelect = async (selectedAddress: any) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const addressComponents = results[0].address_components;
      let country = "";
      let city = "";
      let postcode = "";
      let province = "";
      let address = "";
      let address2 = "";

      for (const component of addressComponents) {
        if (component.types.includes("country")) {
          country = component.long_name;
        }
        if (component.types.includes("locality")) {
          city = component.long_name;
        }
        if (component.types.includes("postal_code")) {
          postcode = component.long_name;
        }
        if (component.types.includes("administrative_area_level_1")) {
          province = component.long_name;
        }
        if (component.types.includes("street_number")) {
          address = component.long_name;
        }
        if (component.types.includes("sublocality")) {
          address2 = component.long_name;
        }
        if (component.types.includes("route")) {
          address = address + " " + component.long_name;
        }
      }

      setCountry(country);
      setCity(city);
      setPostcode(postcode);
      setProvince(province);
      setAddress(address);
      setAddress2(address2);
    } catch (error) {
      console.error("Error fetching address data", error);
    }
  };

  const handleChange = (address: any) => {  
    setAddress(address);
    if (!address) {
      setErrors({ ...errors, addressLine1: 'Address Line 1 is required.' });
    } else {
      setErrors({ ...errors, addressLine1: '' });
    }
  };
  // Step 1: Set up state for form data
  const [formData, setFormData] = useState({
    id: null,
    firstName: "",
    lastName: "",
    emailAddress: "",
    nslType: "",
    companyName: "",
    branchLocation: "",
    signUpNewsLetter: false,
    addressLine1: "",
    addressLine2: "",
    city: "",
    province: "",
    country: "",
    postalCode: "",
    phoneNumber: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",    
    addressLine1: "",
    addressLine2: "",
    city: "",
    province:"",
    country:"",
    postalCode: "",
    phoneNumber: "",
  });

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;

    let errorMessage = "";

    switch (name) {      
      case "firstName":
        errorMessage = value === "" ? "First Name is required." : "";
        break;
      case "lastName":
        errorMessage = value === "" ? "Last Name is required." : "";
        break;
      // case "phoneNumber":
      //   errorMessage = value === "(   )    -    " ? "Phone Number is required." : "";
      //   setPhoneNumber(value);
      //   break;      
      case "addressField1":
        errorMessage =
          value === "" ? "Address Field 1 is required." : "";
        break;
        
      case "city":
        errorMessage = value === "" ? "City is required." : "";
        setCity(value);
        break;
      case "postalCode":
        errorMessage = value === "" ? "Postal Code is required." : "";
        setPostcode(value);
        break;
      case "province":
        errorMessage = value === "" ? "Province is required." : "";
        setProvince(value);
        break;
      case "country":
        errorMessage = value === "" ? "Country is required." : "";
        setCountry(value);
        break;
      default:
        break;
    }
    
    setErrors({ ...errors, [name]: errorMessage });
   // handlechangeData(event);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handlechangeData = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case "addressField2":
        setAddress2(value);
        break;
      case "city":
        setCity(value);
        break;
      case "postalCode":
        setPostcode(value);
        break;
      case "province":
        setProvince(value);
        break;
      case "country":
        setCountry(value);
        break;
      default:
        break;
    }

    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors: any = {};
    if (!formData.firstName) {
      newErrors.firstName = "First Name is required.";
    }
    if (!formData.lastName) {
      newErrors.lastName = "Last Name is required.";
    }
    if (!address) {
      newErrors.addressLine1 = "Address Line 1 is required.";
    }
    // if (!address2) {
    //   newErrors.addressLine2 = "Address Line 2 is required.";
    // }
    if (!city) {
      newErrors.city = "City is required.";
    }
    if (!province) {
      newErrors.province = "Province is required.";
    }
    if (!country) {
      newErrors.country = "Country is required.";
    }
    if (!postcode) {
      newErrors.postalCode = "Postal Code is required.";
    }
    if (!phoneNumber) {
      newErrors.phoneNumber = "Phone Number is required.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length !== 0 ? true : false;
  };

  const handlePhoneNumberChange = (value: string) => {     
    let errorMessage = "";
    const name = "phoneNumber";
    if(value === "(   )    -    " || value === ""){
      errorMessage = "Phone Number is required.";
    }   else{
      errorMessage = "";
    } 
    setPhoneNumber(value);
    setErrors({ ...errors, [name]: errorMessage });
  };

  const handleSelectChangensltype = (event: any) => {
    console.log("==>", event);
    setSelectedOption(event.target.value);
  };

  // Step 3: Create a function to handle form submission
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      // Check if the data has an ID (indicating an update) or not (indicating a new entry)
      if (formData.id) {
        // Make an HTTP request using Axios to update data
        formData.nslType = selectedOption;
        formData.signUpNewsLetter = isToggled;
        formData.city = city;
        formData.country = country;
        formData.postalCode = postcode;
        formData.province = province;
        formData.addressLine1 = address;
        formData.addressLine2 = address2;
        formData.phoneNumber = phoneNumber;

        var isValid = validateForm();
        console.log("=error", Object.keys(errors).length);
        if (isValid) {
          console.log("errors", errors);
          return;
        } else {
          try {
            const response = await axiosInstance.post(
              `/SFAccount/UpdateSFAccount`,
              formData
            );
            // Handle the update response as needed
            console.log("Update Response:", response.data);
            setIsModalAccountSave(true);           
          } catch (error) {
            console.error("API Error:", error);
            // Handle error, show an error message, etc.
          }
        }
      }
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
    }
  };

  const fetchData = async () => {    
    await axiosInstance
      .get(`/SFAccount`)
      .then((response) => {
        // Update the state with fetched data
        setFormData(response.data);
        setSelectedOption(response.data.nsltype);
        setIsToggled(response.data.signUpNewsLetter);

        setCountry(response.data.country);
        setCity(response.data.city);
        setPostcode(response.data.postalCode);
        setProvince(response.data.province);
        setAddress(response.data.addressLine1);
        setPhoneNumber(response.data.phoneNumber);
        setAddress2(response.data.addressLine2);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <div>
        {/* Render the data */}
        {formData && (
          <div>
            <div>
              <Helmet>
                <title>My Info | CSC LED</title>
              </Helmet>
              <CommonLayout>
                <div className="space-y-10 sm:space-y-8">
                  {/* HEADING */}                 
                  <h2 className="text-2xl md:text-2xl font-semibold">
                    Your Information
                  </h2>
                  <div className="flex flex-col md:flex-row" style={{marginTop:"20px"}}>                    
                    <form onSubmit={handleSubmit}>
                      <div className="flex-grow max-w-3xl space-y-6">
                        <div>
                          <Label>First Name</Label>
                          <Input
                            className="mt-1.5"
                            type="text"
                            name="firstName"
                            value={formData.firstName || ""}
                            style={{
                              border:
                                errors.firstName && errors.firstName !== ""
                                  ? "1px solid #fd397a"
                                  : "",
                            }}
                            onChange={handleInputChange}
                          />
                          {errors.firstName && (
                          <span style={{ color: "#fd397a" }}>
                            {errors.firstName}
                          </span>
                        )}
                        </div>
                        <div>
                          <Label>Last Name</Label>
                          <Input
                            className="mt-1.5"
                            type="text"
                            name="lastName"
                            value={formData.lastName || ""}
                            style={{
                              border:
                                errors.lastName && errors.lastName !== ""
                                  ? "1px solid #fd397a"
                                  : "",
                            }}
                            onChange={handleInputChange}
                          />
                           {errors.lastName && (
                            <span style={{ color: "#fd397a" }}>
                              {errors.lastName}
                            </span>
                          )}
                        </div>

                        {/* ---- */}

                        {/* ---- */}
                        <div>
                            <div className="flex items-center">
                              <Label>Email</Label>
                            </div>                            
                            <Tooltip className="w-full" text="Please contact info@csc-led.com if you need to update your account's email address." >
                                <div className="mt-1.5 flex">
                                  <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 
                                    dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                    <i className="text-2xl las la-envelope"></i>
                                  </span>
                                  <Input
                                    className="!rounded-l-none bg-slate-100"
                                    type="text"
                                    name="emailAddress"
                                    readOnly={true}
                                    value={formData.emailAddress}
                                    onChange={handleInputChange}
                                    placeholder="example@email.com"
                                  />
                              </div>
                            </Tooltip>
                        </div>
                        <div>
                          <Label>Newsletter Sign Up</Label>
                          <div className="mt-1.5 flex">
                            <ReactSwitch
                              checked={isToggled}                             
                              onChange={handleToggle}
                              name="signUpNewsLetter"
                            />
                          </div>
                        </div>

                        <div>
                          <Label>
                            Please tell us about yourself so you only receive
                            information relevant to you
                          </Label>
                          <Select
                            className="mt-1.5"
                            value={selectedOption}
                            onChange={handleSelectChangensltype}
                          >
                            {/* <option value="">Select an option</option> */}
                            <option value="Electrician">
                              I'm an electrician, contractor, or installer.
                            </option>
                            <option value="Buyer">
                              I'm a purchaser for a large organization.
                            </option>
                            <option value="Led">
                              I'm an LED wholesaler, distributor, or reseller.
                            </option>
                          </Select>
                        </div>

                        <h2 className="text-2xl sm:text-2xl font-semibold">
                          Your Address
                        </h2>
                        <div>
                          <Label>Company Name</Label>
                          <span className="font-semibold text-gray-500">
                            {" "}
                            (if applicable)
                          </span>
                          <Input
                            className="mt-1.5"
                            type="text"
                            name="companyName"
                            value={formData.companyName}
                            onChange={handleInputChange}
                          />
                        </div>

                        <div>
                          <Label>Branch Location</Label>
                          <span className="font-semibold text-gray-500">
                            {" "}
                            (if applicable)
                          </span>
                          <Input
                            className="mt-1.5"
                            type="text"
                            name="branchLocation"
                            value={formData.branchLocation}
                            onChange={handleInputChange}
                          />
                        </div>

                        <PlacesAutocomplete
                        value={address}
                        onChange={handleChange}
                        onSelect={handleSelect}
                      >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <Label>Address Line 1</Label>
                          <Input
                            name="addressField1"
                            style={{
                              border:
                                errors.addressLine1 && errors.addressLine1 !== ''
                                  ? '1px solid #fd397a'
                                  : '',
                            }}
                            {...getInputProps()}
                          />
                          {errors.addressLine1 && (
                            <span style={{ color: '#fd397a' }}>
                              {errors.addressLine1}
                            </span>
                          )}
                          <div>
                            {loading ? <div>Loading...</div> : null}
                            <div className="">
                              {suggestions.map((suggestion) => {
                                const style = {
                                  backgroundColor: suggestion.active
                                    ? '#e9f1f9'
                                    : '#f8fafc',
                                };

                                return (
                                  <div
                                    className="p-2 px-4 first:rounded-t-sm last:rounded-b-sm overflow-hidden text-gray-700 text-base transition-all cursor-pointer"
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                    })}
                                    key={suggestion.placeId}
                                  >
                                    {suggestion.description}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                    )}
              </PlacesAutocomplete>

                        <div>
                          <Label>Address Line 2</Label>
                          <Input
                            className="mt-1.5"
                            type="text"
                            name="addressField2"
                            // value={address2}
                            value={address2 || ""}
                            onChange={handleInputChange}
                          />
                        </div>

                        <div>
                          <Label>City</Label>
                          <Input
                            type="text"
                            onChange={handleInputChange}
                            value={city || ""}
                            style={{
                              border:
                                errors.city && errors.city !== ""
                                  ? "1px solid #fd397a"
                                  : "",
                            }}
                            className="mt-1.5"
                            name="city"
                          />
                            {errors.city && (
                            <span style={{ color: "#fd397a" }}>{errors.city}</span>
                           )}
                        </div>
                        <div>
                          <Label>Province</Label>
                          <Input
                            type="text"
                            className="mt-1.5"
                            name="province"
                            style={{
                              border:
                                errors.province && errors.province !== ""
                                  ? "1px solid #fd397a"
                                  : "",
                            }}
                            onChange={handleInputChange}
                            value={province}
                          />
                           {errors.province && (
                            <span style={{ color: "#fd397a" }}>{errors.province}</span>
                          )}
                        </div>

                        <div>
                          <Label>Country</Label>
                          <Input
                            type="text"
                            className="mt-1.5"
                            name="country"
                            value={country}
                            style={{
                              border:
                                errors.country && errors.country !== ""
                                  ? "1px solid #fd397a"
                                  : "",
                            }}
                            onChange={handleInputChange}
                          />
                          {errors.country && (
                            <span style={{ color: "#fd397a" }}>{errors.country}</span>
                          )}
                        </div>
                        <div>
                          <Label>Postal Code</Label>
                          <Input
                            className="mt-1.5"
                            type="text"
                            name="postalCode"
                            value={postcode}
                            style={{
                              border:
                                errors.postalCode && errors.postalCode !== ""
                                  ? "1px solid #fd397a"
                                  : "",
                            }}
                            onChange={handleInputChange}
                          />
                           {errors.postalCode && (
                            <span style={{ color: "#fd397a" }}>
                              {errors.postalCode}
                            </span>
                          )}
                        </div>

                        {/* ---- */}
                        <div>
                          <Label>Phone number</Label>
                          <div className="mt-1.5 flex">
                            <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                              <i className="text-2xl las la-phone-volume"></i>
                            </span>
                            <span 
                            style={{
                              border:
                                errors.phoneNumber && errors.phoneNumber !== ""
                                  ? "1px solid #fd397a"
                                  : "",
                            }} className="PhoneMaskingcss block w-full border border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-600 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 !rounded-l-none">
                              <PhoneNumberMask
                                onChange={handlePhoneNumberChange}
                                value={phoneNumber || ""}
                                className={`border ${errors.phoneNumber ? '#fd397a' : ''}`}
                                name="phoneNumber"
                              />
                            </span>
                          </div>
                          {errors.phoneNumber && (
                            <span style={{ color: "#fd397a" }}>
                              {errors.phoneNumber}
                            </span>
                          )}
                        </div>

                        <div className="pt-2">
                          <ButtonPrimary className="w-full md:w-auto" type="submit">
                            Save changes
                          </ButtonPrimary>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </CommonLayout>
            </div>
          </div>
        )}
        <ModalAccountPopup
        isModalOpen={isModalAccountSave}
        onCloseModal={onModalClose}
        modalBody={accountSaveMessage}
      />
      </div>    
    </div>
  );  
};

export default AccountPage;
