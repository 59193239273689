import Divider from "components/Divider";
import HeadingText1 from "components/HeadingText/HeadingText1";
import {
  TechInvParameter,
  TechInventoryArr,
} from "entities/TechnicalInventoryApi";
import React from "react";

const InvParameters = () => {

  return (
    <>
      <Divider/>
      {/* HEADING */}
      <div className="max-w-screen-sm">
        <HeadingText1 className="">Inventory Parameters</HeadingText1>
        <div className="mt-2 dark:text-slate-400 text-sm sm:text-base">
          Available inventory information for your website.
        </div>
      </div>
      <Divider/>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Type
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white text-sm">
                {TechInventoryArr.map((obj) => (
                  <tr key={obj.name} className="even:bg-gray-50">
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-3">
                      {obj.name}
                    </td>
                    <td className="px-3 py-4 text-base">
                      {obj.description}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 italic">
                      {obj.dataType}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvParameters;
