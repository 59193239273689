import React, { FC } from "react";
import { Helmet } from "react-helmet-async";

interface HelmetComponentProps {
  title: string;
  metaDescription?: string;
  metaKeywords?: string;
}

const HelmetComponent: FC<HelmetComponentProps> = ({
  title = "CSC LED",
  metaDescription,
  metaKeywords,
}) => {
  const renderMetaDescription = () => {
    return <meta name="description" content={metaDescription} />;
  };

  const renderMetaKeywords = () => {
    return <meta name="keywords" content={metaKeywords} />;
  };

  return (
    <Helmet>
      <title>{title}</title>
      {process.env.REACT_APP_ENV === "production" && (
        <>
          {/**Render Meta Description */}
          {metaDescription && renderMetaDescription()}

          {/**Render Meta keywords */}
          {metaKeywords && renderMetaKeywords()}
        </>
      )}
    </Helmet>
  );
};

export default HelmetComponent;
