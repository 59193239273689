import React, { useEffect, useState } from 'react';
import axiosInstance from 'api/AxiosInstance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
import "./Languages.css"
import { useBackgroundContext } from 'hooks/useBackgroundContext';

declare global {
  interface Window {
    googleTranslateElementInit: () => void;
    google: any; // Define the global 'google' object
  }
}

interface SiteLanguage {
  languageCultureName: string;
  languageFullName: string;
}

const SiteLanguage: React.FC = () => {
  const [languages, setLanguages] = useState<SiteLanguage[]>([]);
  const [activeLang, setActiveLang] = useState<string>('en'); // Default to 'en'
  const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);

  const {isBackgroundImage} = useBackgroundContext();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get('/Home/GetSiteLanguages');
        setLanguages(response.data.data); // Assuming response.data.data is an array of SiteLanguage objects
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const addGoogleTranslate = () => {
      if (!window.google || !window.google.translate) {
        const googleTranslateScript = document.createElement('script');
        googleTranslateScript.src =
          '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
        googleTranslateScript.type = 'text/javascript';
        googleTranslateScript.async = true;
        googleTranslateScript.onload = () => setScriptLoaded(true);
        googleTranslateScript.onerror = () => console.error('Failed to load Google Translate script');
        document.head.appendChild(googleTranslateScript);
      } else {
        setScriptLoaded(true);
      }
    };

    addGoogleTranslate();
  }, []);

  useEffect(() => {
    const initializeGoogleTranslate = () => {
      if (window.google && window.google.translate && typeof window.google.translate.TranslateElement === 'function') {
        window.googleTranslateElementInit = () => {
          new window.google.translate.TranslateElement(
            {
              pageLanguage: 'en', // Default page language
              includedLanguages: languages.map(lang => lang.languageCultureName).join(','),
              layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
            },
            'google_translate_element'
          );
        };

        window.googleTranslateElementInit();
      } else {
        console.error('Google Translate Element is not available');
      }
    };

    if (scriptLoaded) {
      initializeGoogleTranslate();
    }
  }, [languages, scriptLoaded]);

  useEffect(() => {
   // debugger;
    const assignUnderlineLang = () => {      
      let cookieVal = Cookies.get("googtrans");
    //  console.log("Cookie Value:", cookieVal); // Log the initial cookie value
  
      if (!cookieVal || cookieVal === undefined) {
        console.log("No cookie found. Setting default to 'en'");
        setActiveLang('en'); // Default to 'en' if cookie is not set
        Cookies.set(
          "googtrans",
          '/en',
          { expires: 365  }
        ); // Expires in 7 days
        console.log('Cookie set on page load while cookie is undefined:', Cookies.get('googtrans')); // Log to check the value
      } else {
        cookieVal = cookieVal.replace('/en', '').replace('/', '');
      //  console.log("Processed Cookie Value:", cookieVal); // Log the processed cookie value
        
        if (cookieVal === '') {
          cookieVal = 'en';
        }
        setActiveLang(cookieVal);
      }
    };
  
    assignUnderlineLang();
  }, []);

  const handleLanguageChange = (lang: string) => {

    const env = process.env.REACT_APP_ENV;

    const domainMap: { [key: string]: string } = {
      'localhost' : 'localhost',
      'dev2024site.com': 'dev2024site.com',
      'staging.dev2024site.com': 'staging.dev2024site.com',
      'csc-led.com': 'csc-led.com'
   };
  
    console.log('Environment:', env);
    console.log('Hostname:', window.location.hostname);

    console.log('Domain Map:', domainMap);    

    setActiveLang(lang);

    // Remove the existing cookie
    Cookies.remove("googtrans");
    console.log('Cookie removed.');

    // Determine the correct domain based on the hostname
    const domain = Object.keys(domainMap).find(key => window.location.hostname.includes(key)) || 'dev2024site.com';

    if (lang) {
        Cookies.set("googtrans", `/${lang}`, { 
            expires: 365, 
            secure: true, 
            sameSite: 'None', 
            domain: domainMap[domain], 
            path: '/' 
        });
        console.log('Cookie set:', Cookies.get('googtrans'));
    }

    setTimeout(() => {
        window.location.reload();
    }, 100);
};

  return (
    <div className={`flex items-center dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group ${isBackgroundImage ? `text-custom-grey`:`text-black`}`}>
      <span className="flex-shrink-0 w-5 lang-globe lang-translator">
        <FontAwesomeIcon icon={faGlobe} />
      </span>
      {languages && languages.map((item, index) => (
        <React.Fragment key={item.languageCultureName}>
          {index > 0 && (
            <span className="mx-1 sm:inline lang-translator lang-seperator">
              |
            </span>
          )}
          <span
            className={`cursor-pointer sm:inline md:text-sm text-lg language-changes lang-char lang-translator ${
              activeLang === item.languageCultureName ? 'lang-underline' : ''
            }`}
            data-lang={item.languageCultureName}
            onClick={() => handleLanguageChange(item.languageCultureName)}
            translate="no"
          >
            {item.languageFullName}
          </span>
        </React.Fragment>
      ))}
    </div>
  );
};

export default SiteLanguage;
