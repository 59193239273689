import HeadingText2 from "components/HeadingText/HeadingText2";
import { BaseProp } from "entities/BaseProp";
import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

export interface TermConditionProps extends BaseProp {
}

const content = [
  "CSC LED Corp. (formerly known as Earth-line SSL) has been in the LED business since 2006.",
  "We partner with our electrical wholesale customers and provide affordable and reliable commercial and industrial LED products that are made with only the best LED chips, drivers, and thermal management.",
  "We offer competitive prices, quality products, and outstanding customer service.",
  "In the event you choose to pay with credit card and the payment will be processed via a Canadian acquirer, these terms are an agreement between you and CSC LED Corp. For any other type of purchases, these terms are an agreement between you and CSC LED Corp and goods and/or services will be delivered by CSC LED Corp directly.",
  "1. Terms and Conditions to Govern - These terms and conditions represent the final and complete agreement of the parties and no terms or conditions in any way modifying or changing the provisions stated herein shall be binding upon our Company unless made in writing and signed and approved by an officer or other authorized person at our Company. No modification of any of these terms shall be modified by our Company's shipment of goods following receipt of Buyers purchase order, shipping request or similar forms containing printed terms and conditions additional to or in conflict with the terms herein. If any term, clause, or provision is declared to be held invalid by a court of competent jurisdiction, such declaration or holding shall not affect the validity of any other term, clause or provision herein contained.",
  "2. Acceptance of Orders - All orders are subject to written price verification by authorized Company personnel unless designated in writing to be firm for a specified period of time. Shipment of goods without written price verification does not constitute acceptance of the price contained in the order.",
  "3. Substitution - Our Company reserves the right, without prior notification, to substitute an alternative product of like kind, quality and function. If the Buyer will not accept a substitute, the Buyer must specifically declare that no substitution is allowed when the Buyer requests a quote, if such request for quote is made, or, if no request for quote was made when placing an order with the our Company.",
  "4. Price - Prices quoted, including any transportation charges, are valid for 10 days unless designated as firm for a specific period pursuant to a written quote or written sales acceptance issued or verified by an officer or other authorized personnel of our Company. A price designated as firm for a specific period may be revoked by our Company if the revocation is in writing and is emailed to the Buyer, or the message is conveyed by telephone, prior to the time a written acceptance of the price is received by our Company. All prices and deliveries are F.O.B. shipping point. Our Company reserves the right to cancel orders in the event selling prices which are lower than prices quoted are established by government regulations.",
  "5. Transportation - Unless otherwise provided, our Company shall use its judgment in determining carrier and routing. In either case, our Company shall not be liable for any delays or excessive transportation charges resulting from its selection.",
  "6. Packing - Unless otherwise provided, our Company will comply only with its minimum packing standards for the method of transportation selected. The cost of all special packing, loading or bracing requested by Buyer will be paid for by Buyer. All costs of packing and shipment for Buyer's special equipment shall be paid for by the Buyer. Tail gate charges are extra.",
  "7. Payment Terms - Any discounts offered at the Company's choosing apply only to the invoiced value of the material (not to taxes or freight charges). Our Company reserves the right to require advance payment or satisfactory security for the goods if the financial condition of the Buyer so warrants as determined by our Company. If the Buyer fails to make payment in accordance with the terms of this agreement or any collateral agreement, or fails to comply with any provisions hereof, our Company may, at its option (and in addition to other remedies), cancel any unshipped portion of this order. Buyer is to remain liable for all unpaid accounts.",
  "8. Taxes and Import/Export Licenses - Prices do not include taxes. Taxes are paid by the Buyer upon invoice from our Company unless the Buyer provides a valid exemption certificate acceptable to the taxing authority or unless our Company is forbidden by law from collection of said taxes from the Buyer. Import or export licenses are to be secured by the Buyer.",
  "9. Title and Risk of Loss - Delivery to the carrier shall constitute delivery to the Buyer, and thereafter risk of loss or damage shall pass to the Buyer. Any claim of the Buyer relative to damage during shipping or delivery should be made directly to the carrier. Any claims by the Buyer against our Company for shortage or damage occurring prior to such delivery to the carrier must be made within five (5) days after receipt of the goods and accompanied by the original transportation bill signed by the carrier noting that carrier received the goods from our Company in the condition claimed. Notwithstanding passage of the risk of loss to the Buyer, title and right of possession to the goods sold hereunder shall remain with our Company until all payments hereunder, including deterred payments evidenced by notes or otherwise, interest, carrying charges, and attorneys' fees, shall have been made in cash, and Buyer agrees to do all acts necessary to perfect and maintain such right and title in our Company.",
  "10. Return of Products - Goods can be returned in 30 days. Wrong-size items and quality problem items can be exchanged. Customers returning goods are responsible for freight charges and re-stocking charges may apply.",
  "11. Force Majeure - Our Company shall not be liable for failure to perform its obligations resulting directly or indirectly from or contributed to by acts of God; acts of the Buyer, civil or military authority, including wage and price controls; fire; war; riot; delays in transportation; lack of or inability to obtain raw materials (including energy sources), components, labour, fuel or supplies; or other circumstances beyond our Company's reasonable control, whether similar or dissimilar to the foregoing. If certain quantities are affected and other quantities are not, the quantities affected shall be eliminated without liability, but the agreement shall remain unaffected. Our Company may during any period of shortage due to any of the said causes, allocate its supply of such raw materials among its various users thereof in any manner which the Company deems fair and reasonable. In no event shall our Company be liable for special or consequential damages for any delay for any cause.",
  "12. Reasonable Attorney's Fees - In the event suit or other proceedings shall be brought for the recovery of the purchase price, or any unpaid balance, or the breach by the Buyer of any term herein contained, the Buyer shall pay to our Company, in addition to any damages proved by law, reasonable attorney's fees and costs of collection.",
  "13. Liability - Our Company shall not be responsible, obligated, or liable for any injury or damage resulting from an application or use of its products, either singularly or in combination with other products, arising out of acceptance of this order. Our Company shall have no liability for errors in weight or quantity delivered unless claim is made by the Buyer within five (5) days after receipt of shipment and accompanied by the original transportation bill signed by the carrier noting that carrier received the goods from our Company in the condition claimed. If such timely claim is made by the Buyer, and the claim is deemed valid by our Company, our Company may fulfill its responsibility by either shipping the quantity necessary to make good the deficiency, or at our Company's option, crediting the Buyer with the invoice price of the deficiency.",
  "14. Warranty - All goods sold by our Company are warranted to the Buyer to be free from defects in material and workmanship, and manufactured in accordance with industry standards. The foregoing warranty is nonassignable and in lieu of and excludes all other warranties not expressly set forth herein, whether express or implied by operation of law or otherwise including but not limited to any implied warranties of merchantability or fitness. No agent, employee, or representative of our Company has any authority to bind our Company to any representation, affirmation, or warranty concerning the goods and any such representation, affirmation, or warranty shall not be deemed to have become a part of the basis of this agreement and shall be unenforceable. Any claimed defect in material or workmanship shall be deemed waived by the Buyer unless submitted to our Company in writing within five (5) days from the date the goods are received by the Buyer.",
  "Our Company shall not be liable under the foregoing warranty if any loss or damage is caused by improper application or use of the goods. Our Company disclaims all liability with respect to the design of the goods and makes no warranty with respect to such design. This warranty is in lieu of and excludes all other warranties, whether express, implied, or statutory, including implied warranties of merchantability or fitness.",
  "15. Remedies and Limitation of Liability - Our Company shall not be liable for incidental or consequential losses, damages, or expenses arising directly or indirectly from the sale, handling or use of the goods, or from any other cause relating thereto. Our Company's liability, in any case, including for claims of breaches of warranty or negligence is exclusively limited, at our Company's option, to the replacement of goods not complying with this agreement, the repayment of, or crediting Buyer with, an amount equal to the purchase price of such goods, or repairing or arranging for repair of the goods. If our Company requests the return of the goods, the goods will be delivered to our Company in accordance with our Company's instructions. The remedies contained in this paragraph constitute the sole recourse of Buyer against our Company for breach of any of our Company's obligations, whether of warranty or otherwise. as long as our Company makes a good faith effort to rectify any breach, the remedies provided for herein shall be deemed satisfied.",
  "16. Selection - The Buyer represents that the goods sold hereunder are fit for their actual or intended use and that the Buyer placed no reliance on our Company's skill or judgment in selecting suitable goods or materials or in the design of suitable goods and materials. Buyer represents that the use and installation of the goods shall be made in compliance with all applicable government requirements. The Buyer will defend, indemnify and hold harmless our Company, its successors, assigns and subsidiaries from and against all costs (including attorney's fees), damages and liabilities resulting from actual or alleged claims asserted or any penalties proposed or assessed our Company for any alleged violation of any federal, slate or local law, rule, regulation or standard, by reason of or in connection with any use of the goods delivered hereunder.",
  "17. Choice of Law - This agreement and matters connected with the performance thereof shall be construed in accordance with, and governed by, the law of the province of Ontario as if it were executed and performed entirely within the state of Ontario. Further, it shall be construed to be between merchants.",
  "18. General - Our Company specifically represents that any goods to be delivered hereunder will be produced in compliance with the requirements of the fair labour standard act of 1939, as amended.",
  "19. Emails - When you register on our site and click on the button to subscribe, you agree that we can send you our updates and promotional emails. If you don't want to receive them anymore, you can unsubscribe at any time.",
];

const TermCondition: FC<TermConditionProps> = ({ className = "" }) => {
  return (
    <div className={`nc-TermCondition ${className}`} data-nc-id="TermCondition">
      <Helmet>
        <title>Terms and Conditions | CSC LED</title>
      </Helmet>

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-28">
        <div className="space-y-10 lg:space-y-14">
          {/* HEADING */}
          <div className="">
            <HeadingText2 className="">Terms And Conditions</HeadingText2>
            {content.map((paragraph, index) => (
              <span
                key={index}
                className="block mt-4 text-black dark:text-neutral-400 text-sm sm:text-base"
                dangerouslySetInnerHTML={{ __html: paragraph }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermCondition;
